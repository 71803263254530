import React, { FC, memo, useMemo, useState } from 'react';
import testingVar from 'utils/testingVar';
import { Button, FlexSpacer } from '@scriptaddicts/yamm-ui-components';
import {useLocation} from 'react-router-dom';
import { logDisplayModal } from 'utils/userJourneyLogging';
import {
  Container,
  MenuButton,
  MenuIcon,
  AccountPlaceholder,
  Help,
  HelpIcon,
  SideContainer,
  ButtonLink,
} from 'seats/components/SeatsTopBar/styles';
import {
  OtherWorkspaceDecorator,
  useWorkspaceContext,
} from 'data/context/WorkspaceContext';
import { UpgradeUserDialog } from 'widgets/UpgradeUser';
import { Props } from 'seats/components/SeatsTopBar/types';
import {clearSeatQuantityInSessionStorage} from "seats/data/context/CheckoutContext/utils";
import AccountDropdown from "seats/components/AccountDropdown";

const SeatsTopBar: FC<Props> = ({
  name,
  photo,
  email,
  id,
  initials,
  shouldShowUpgradePlanButton,
  shouldShowBillingButton,
  onOpen,
  onLogout,
  onAccountSwitch,
  spaceUrlPrefix,
  ...props
}) => {
  const { pathname, search } = useLocation();
  const { otherWorkspaces, id: workspaceId } = useWorkspaceContext();
  const currentWorkspace = otherWorkspaces.find((a) => a.id === workspaceId);

  const [showUpgradeUserDialog, setShowUpgradeUserDialog] = useState<boolean>(
    false,
  );

  const isFreeMemberInProSpace = useMemo(() => {
    if (currentWorkspace === undefined) return false;
    return new OtherWorkspaceDecorator(
      currentWorkspace,
    ).isFreeMemberInProSpace();
  }, [currentWorkspace]);

  const upgradeButtonText = 'Upgrade';
  return (
    <Container {...props}>
      {onOpen && (
        <MenuButton data-test-id={testingVar('submit-open')} onClick={onOpen}>
          <MenuIcon />
        </MenuButton>
      )}
      <FlexSpacer />
      <SideContainer>
        {shouldShowUpgradePlanButton && (
          <ButtonLink
            data-log-event="go_to_checkout"
            data-log-event-data={JSON.stringify({
              displayName: upgradeButtonText,
            })}
            aria-label="Upgrade plan"
            to={`${spaceUrlPrefix}/checkout/details`}
            data-test-id="upgrade-plan"
            onClick={() => {
              clearSeatQuantityInSessionStorage();
            }}
          >
            {upgradeButtonText}
          </ButtonLink>
        )}
        {isFreeMemberInProSpace && (
          <Button
            aria-label="Upgrade"
            data-log-event="open_upgrade_modal"
            data-log-event-data={JSON.stringify({
              displayName: upgradeButtonText,
            })}
            onClick={() => {
              setShowUpgradeUserDialog(true);
              setTimeout(() =>
                logDisplayModal(
                  'upgrade_process_info',
                  pathname,
                  search,
                  workspaceId,
                ),
              );
            }}
          >
            {upgradeButtonText}
          </Button>
        )}
        {showUpgradeUserDialog && isFreeMemberInProSpace && (
          <UpgradeUserDialog
            show={showUpgradeUserDialog}
            close={() => {
              setShowUpgradeUserDialog(false);
            }}
          />
        )}
        <Help
          href="https://support.yet-another-mail-merge.com/hc/en-us"
          target="_blank"
          rel="noopener noreferrer"
        >
          <HelpIcon />
        </Help>
        {name && email ? (
          <AccountDropdown
            {...{
              name,
              photo,
              email,
              id,
              initials,
              onLogout,
              onAccountSwitch,
              shouldShowBillingButton,
            }}
          />
        ) : (
          <div>
            <AccountPlaceholder />
          </div>
        )}
      </SideContainer>
    </Container>
  );
};

export default memo(SeatsTopBar);
