import styled from 'styled-components';
import {from} from 'styles/media';
import {FlexContainer, Heading,} from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div`
    display: flex;
    gap: 16px;
    flex-direction: column;
    padding: 24px;
    padding-top: 28px;
    padding-bottom: 28px;

    ${from.tablet} {
        padding: 36px;
        width: 500px;
    }
`;

export const Header = styled(Heading)`
    text-align: center;

`
export const HeaderContainer = styled(FlexContainer).attrs({flex: "horizontal"})`
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
`

export const ButtonContainer = styled(FlexContainer).attrs({flex: "horizontal"})`
    display: flex;
    padding-top: 16px;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`

