import {DispatchWithoutAction, useCallback, useEffect,} from 'react';
import {from, useMediaQuery} from 'styles/media';
import useSubscriptionSeat from "seats/data/hooks/useSubscriptionSeat";
import {useWorkspaceContext} from 'data/context/WorkspaceContext';
import {useCancelScheduledRemoval, useWorkspaceActiveSubscriptionV2} from "seats/data/hooks/useSeatsWorkspaceBilling";

export const useConnect = ({onClose}: {
  onClose: DispatchWithoutAction;
}) => {
  const {id: workspaceId, user, isLoading: isLoadingWorkspace, setBillingAlerts} = useWorkspaceContext();
  const userRole = user?.role;
  const {subscriptionSeat, isLoadingSubscriptionSeat} = useSubscriptionSeat(workspaceId, userRole);
  const {data: activeSubscription, isLoading: isLoadingActiveSubscription} = useWorkspaceActiveSubscriptionV2({
    workspaceId,
    userRole
  });

  const {
    mutate,
    isLoading: isCancelScheduledRemovalLoading,
    isSuccess: isCancelScheduledRemovalSuccess,
  } = useCancelScheduledRemoval();

  const downgradeClick = useCallback(() => {
    if (!!activeSubscription?.subscriptionId && !!workspaceId) {
      mutate({subscriptionId: activeSubscription?.subscriptionId, workspaceId});
    }
  }, [activeSubscription?.subscriptionId, workspaceId, mutate]);

  useEffect(() => {
    if (isCancelScheduledRemovalSuccess) {
      setBillingAlerts({canceledScheduledDowngrade: true})
      onClose();
    }
  }, [isCancelScheduledRemovalSuccess]);

  const isMobile = !useMediaQuery(from.tablet);

  return {
    isLoading: isLoadingSubscriptionSeat || isLoadingWorkspace || isLoadingActiveSubscription,
    isSubmitting: isCancelScheduledRemovalLoading,
    downgradeClick,
    isMobile,
    subscriptionSeat,
  };
};
