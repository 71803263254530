import {Button, Dialog, FlexContainer, Text} from '@scriptaddicts/yamm-ui-components';
import React, {FC} from 'react';
import {useConnect} from 'seats/widgets/CancelScheduledDowngrade/connect';
import {ButtonContainer, Container, Header, HeaderContainer} from 'seats/widgets/CancelScheduledDowngrade/styles';
import {Props} from 'seats/widgets/CancelScheduledDowngrade/types';
import {plurify} from "utils/plurify";
import {formatLongMonthDayYear} from "utils/dates";
import {Stronger} from "seats/components/SeatStyledComponents/styles";

const CancelScheduledDowngrade: FC<Props> = ({
                                             show,
                                             onClose,
                                           }) => {
  const {
    isLoading,
    isSubmitting,
    isMobile,
    subscriptionSeat,
    downgradeClick
  } = useConnect({
    onClose,
  });


  return (
    <Dialog
      show={show}
      onClose={() => {
        if (isLoading) {
          return;
        }
        onClose();
      }}
      close={isMobile ? 'overlay' : 'both'}
    >
      <Container>
        <HeaderContainer>
          <Header size="large">Cancel downgrade</Header>
        </HeaderContainer>
        <FlexContainer flex="vertical" gap="base">
          <Text type="paragraph">
            You have a scheduled downgrade to <Stronger>{subscriptionSeat.scheduledRemoval?.targetSeatsQuantity} {plurify(
            subscriptionSeat.scheduledRemoval?.targetSeatsQuantity,
            'seat',
            'seats'
          )}</Stronger> that will take effect on {formatLongMonthDayYear(subscriptionSeat.scheduledRemoval?.removeSeatsAtTimestamp || 0)}. Do you want
            to cancel the downgrade?
          </Text>
        </FlexContainer>
        <ButtonContainer>
          <Button variant="primary" aria-label="yes-cancel-downgrade" onClick={downgradeClick} disabled={isLoading || isSubmitting}
                  loading={isSubmitting}>Yes,
            cancel downgrade</Button>
          <Button variant="secondary" aria-label="no-cancel-downgrade" onClick={onClose} disabled={isLoading || isSubmitting}>No, close</Button>
        </ButtonContainer>
      </Container>
    </Dialog>
  );
};

export default React.memo(CancelScheduledDowngrade);
