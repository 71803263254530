/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  DropdownContainer,
  DropdownList,
  Icon,
} from '@scriptaddicts/yamm-ui-components';
import React, {
  Dispatch,
  DispatchWithoutAction,
  FC,
  memo,
  useMemo,
} from 'react';
import { plurify } from 'utils/plurify';
import { useConnect } from './connect';
import {
  ActionContainer,
  LightBlueDown,
  Subtitle,
  Title,
  TitleContainer,
  Trigger,
  SubtitlePlaceholder,
  TitlePlaceholder,
  Container,
  // CreateItem,
  Item,
  CustomItem,
  AddonSpaceLabel,
  TitleLabelContainer,
} from './styles';

type MenuItemProps = {
  workspaceId: string;
  workspaceName: string;
  addonWorkspaceId: string | undefined;
  selected: boolean;
  // eslint-disable-next-line react/require-default-props
  onSelected?: Dispatch<string | ReadonlyArray<string> | number | undefined>;
};
const MenuItem = ({
  addonWorkspaceId,
  workspaceId,
  workspaceName,
  ...props
}: MenuItemProps) => {
  if (workspaceId === addonWorkspaceId) {
    return (
      <CustomItem value={workspaceId} {...props}>
        <div title={workspaceName}>
          <Icon name="send" />
          <span>{workspaceName}</span>
        </div>
        <AddonSpaceLabel>Emails are sent from this space</AddonSpaceLabel>
      </CustomItem>
    );
  }

  return (
    <Item value={workspaceId} {...props}>
      {workspaceName}
    </Item>
  );
};

export type SubtitleProps = {
  seatCount: number | undefined;
  legacyType: 'TEAM' | 'PROFESSIONAL' | 'PERSONAL' | 'UNLIMITED' | undefined;
  plan: "LEGACY" | "FREE" | "PRO" | "PREMIUM" | undefined;
  planText: string;
  isGmailUser: boolean;
}
export const SubtitleComponent = ({seatCount, legacyType, planText, plan, isGmailUser}: SubtitleProps) => {
  const showSeatCount = (typeof seatCount === 'number' && seatCount > 0)
      && legacyType !== 'PERSONAL'
      && legacyType !== 'PROFESSIONAL'
      && !isGmailUser;
    const seatOrUser = (plan === 'PRO' || plan === 'PREMIUM') ? 'seat' : 'user';

    return (
        <Subtitle>
            {showSeatCount
                ? `${planText} - ${seatCount} ${plurify(seatCount, seatOrUser)}`
                : planText
            }
        </Subtitle>
    );
};

const WorkspaceDropdown: FC<{
  onCreate: DispatchWithoutAction;
  onClose?: DispatchWithoutAction;
}> = ({ onCreate, onClose }) => {
  const {
    isLoading,
    id,
    name,
    plan,
    legacyType,
    seatCount,
    workspaces,
    onChange,
    addonWorkspaceId,
    isSupportSub,
    isGmailUser
  } = useConnect();

  const planText = useMemo(() => {
    if (isSupportSub) {
      return 'Custom';
    }

    switch (plan) {
      case 'LEGACY':
        return (() => {
          switch (legacyType) {
            case 'TEAM':
            case 'UNLIMITED':
              return 'Team (legacy)';
            case 'PROFESSIONAL':
              return 'Professional (legacy)';
            case 'PERSONAL':
              return 'Personal (legacy)';
            default:
              return undefined;
          }
        })();
      case 'PREMIUM':
        return 'Premium';
      case 'PRO':
        return 'Pro';
      case 'FREE':
        return 'Free';
      default:
        return undefined;
    }
  }, [plan, legacyType, isSupportSub]);

  return (
    <Container>
      <DropdownContainer>
        <Trigger
          aria-controls="workspace-menu"
          disabled={isLoading || !name || !planText}
        >
          <TitleContainer>
            {isLoading || !name ? (
              <TitlePlaceholder type="span" size="large" width={122} />
            ) : (
              <TitleLabelContainer>
                {id === addonWorkspaceId && <Icon name="send" />}
                <Title>{name}</Title>
              </TitleLabelContainer>
            )}
            {isLoading || !planText ? (
              <SubtitlePlaceholder type="span" size="base" width={80} />
            ) : <SubtitleComponent isGmailUser={isGmailUser} seatCount={seatCount} legacyType={legacyType} planText={planText} plan={plan} />}
          </TitleContainer>
          <ActionContainer>{!isLoading && <LightBlueDown />}</ActionContainer>
        </Trigger>
        <DropdownList
          id="workspace-menu"
          minElement={2}
          maxElement="auto"
          onSelected={(value) => {
            if (value === 'create-workspace') {
              onCreate();
            } else {
              onChange(value as string);
            }
            if (onClose) onClose();
          }}
          style={{
            marginTop: '-8px',
            border: 'none',
          }}
        >
          {workspaces.map(({ id: workspaceId, name: workspaceName }) => (
            <MenuItem
              addonWorkspaceId={addonWorkspaceId}
              key={workspaceId}
              workspaceId={workspaceId}
              workspaceName={workspaceName}
              selected={workspaceId === id}
            />
          ))}
          {/* <CreateItem value="create-workspace">Create a new space</CreateItem> */}
        </DropdownList>
      </DropdownContainer>
    </Container>
  );
};

export default memo(WorkspaceDropdown);
