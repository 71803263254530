import React, { memo } from 'react';
import { useMe } from 'data/hooks/useMe';
import {isOfflineSubscription, isSupportGivenSubscription} from "utils/subscriptions";
import {useWorkspaceActiveSubscriptionV2} from "seats/data/hooks/useSeatsWorkspaceBilling";
import { Chevron, Text } from 'seats/components/AccountDropdown/styles';
import {
  getBadgeClassForWorkspace,
  getPrettyUserPlan,
  getRecipientsQuotaPerDay,
  toTitleCase,
} from 'utils/workspace';
import {
  OtherWorkspace,
  OtherWorkspaceDecorator,
} from 'data/context/WorkspaceContext';
import {SpacePlanBadge} from "utils/workspace/styles";
import { DropDownSpaceDetail } from 'seats/components/AccountDropdown/AccountDropDownSpace/styles';

const AccountDropdownSpace = ({
  activeWorkspace,
  showChevron,
}: {
  activeWorkspace: OtherWorkspace | undefined;
  showChevron: boolean;
}) => {
  const { data: me } = useMe();
  const {data: activeSubscription} = useWorkspaceActiveSubscriptionV2({
    workspaceId: activeWorkspace?.id,
    userRole: activeWorkspace?.role
  });
  const { workspacePlan, userPlan } = activeWorkspace?.workspaceUserFeature || {};

  const hasOfflineSubscription = activeSubscription && isOfflineSubscription(activeSubscription);
  const hasSupportGivenSubscription = activeSubscription && isSupportGivenSubscription(activeSubscription);

  const isSpace = new OtherWorkspaceDecorator(activeWorkspace).hasSpacePlan();

  return (
    <>
      <DropDownSpaceDetail>
        <Text style={{fontWeight: 500}}>
          {hasOfflineSubscription || hasSupportGivenSubscription ? 'Your emails are sent from:' : 'Active space:'}
        </Text>
        <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
          <Text>{activeWorkspace?.name}</Text>
          {isSpace && (
            <SpacePlanBadge
              className={getBadgeClassForWorkspace(activeWorkspace)}
            >
              <Text>{toTitleCase(workspacePlan)}</Text>
            </SpacePlanBadge>
          )}
        </span>
        {hasOfflineSubscription && <Text>Offline subscription</Text>}
        {hasSupportGivenSubscription && <Text>Support given subscription</Text>}
        {!hasOfflineSubscription && !hasSupportGivenSubscription && (
          <>
            <Text>{getPrettyUserPlan(userPlan)} user</Text>
            <Text>
              Up to {getRecipientsQuotaPerDay(userPlan, me?.freeUserQuota)} recipients / day
            </Text>
          </>
        )}
      </DropDownSpaceDetail>
      {showChevron && <Chevron />}
    </>
  );
};

export default memo(AccountDropdownSpace);
