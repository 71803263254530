import styled from 'styled-components';
import {Button} from "@scriptaddicts/yamm-ui-components";

export const AlertManageUsersButton = styled(Button).attrs({variant:"transparent"})`
    display: flex;
    height: 32px;
    padding: 12px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid ${({ theme }) => theme.colors.green400};
    background: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.green700};
    font-family: ${({ theme }) => theme.fonts.inter};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 125%; /* 16.25px */
    letter-spacing: -0.195px;
    /* Shadow/Small */
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    margin-top: 10px;

    &:hover {
        background-color: ${({ theme }) => theme.colors.gray50};
    }
`;
