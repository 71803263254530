import { useConnect as useCurrentPlanConnect } from 'seats/containers/Main/Workspaces/Billing/CurrentPlan/connect';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import useQueryParams from 'utils/useQueryParams';
import useWorkspaceLocation from 'utils/router/useWorkspaceLocation';
import { useRequireUserFeature } from 'utils/useRequireUserFeature';
import { useNotificationMessages } from 'data/hooks/useNotificationMessages';
import { SHOW_PLANS } from 'data/queryParams';
import { useMe } from 'data/hooks/useMe';
import { useWorkspaceActiveSubscriptionV2 } from 'seats/data/hooks/useSeatsWorkspaceBilling';
import {useEffect} from "react";
import { useConnect as usePurchasePlansConnect } from './PurchasePlans/connect';

export const useConnect = () => {
  const { url } = useWorkspaceLocation();
  useRequireUserFeature('canAccessWorkspaceBilling', `${url}/space/general`);

  const { isGmailUser } = useMe();
  const params = useQueryParams();
  const requestedPlan = params.get('plan');

  const validPlans = {
    gmail: ['pro-gmail', 'PERSONAL'],
    domain: ['pro-google-workspace', 'PROFESSIONAL'],
  };
  const accountType = isGmailUser ? 'gmail' : 'domain';
  const wrongPlanRequested =
    requestedPlan && !validPlans[accountType].includes(requestedPlan);

  const queryParams = useQueryParams();
  const { id, details, user, billingAlerts, setBillingAlerts} = useWorkspaceContext();
  const { data: activeSubscription } = useWorkspaceActiveSubscriptionV2({
    workspaceId: id,
    userRole: user?.role,
  });

  const messagesHandler = useNotificationMessages();

  const currentPlanConnect = useCurrentPlanConnect();
  const purchasePlansConnect = usePurchasePlansConnect();
  const isFree =
    !activeSubscription || activeSubscription?.workspacePlan === 'FREE';

  useEffect(() => {
    return () => {
      setBillingAlerts({});
    };
  }, [id]);

  return {
    showPurchasePlans: queryParams.has(SHOW_PLANS),
    workspaceDetails: details,
    isFree,
    messagesHandler,
    currentPlanConnect,
    purchasePlansConnect,
    wrongPlanRequested,
    queryParams,
    accountType,
    billingAlerts
  };
};
