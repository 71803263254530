import styled from "styled-components";
import {DropdownList, DropdownListItem, Text} from "@scriptaddicts/yamm-ui-components";

export const Trigger = styled.button`
    display: flex;
    padding: 9px 13px;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    justify-content: space-between;
    width: 100%;
    background-color: ${({theme}) => theme.colors.gray50};
    border-radius: 5px;
    border: 1px solid ${({theme}) => theme.colors.gray400};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const SeatDropdownList = styled(DropdownList)`
    margin-top: 0 !important;
    width: 89px;
    padding: 10px 8px;
    //flex-direction: column;
    //align-items: center;
    gap: 6px;
    border-radius: 6px;
    border: 0.5px solid rgba(0, 0, 0, 0.12);
    background: #F1F1F1;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(30px);
`;

export const SeatDropdownListItem = styled(DropdownListItem)`
    border-radius: 0 !important;
    display: flex;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    background: #F1F1F1;
`;

export const SeatDropdownListItemText = styled(Text).attrs({
  type: 'span',
})`
    color: ${(props) => props.theme.colors.gray800};
    font-family: ${(props) => props.theme.fonts.inter};
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.25px;
    font-variant-numeric: slashed-zero;
`;
