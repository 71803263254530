import React from 'react';
import {Placeholder} from '@scriptaddicts/yamm-ui-components';
import {
  ComputedBillingPurchasePlansItem,
  ComputedBillingPurchasePlansItemDetail,
  StyledDifferenceText
} from 'seats/containers/Checkout/common/styles';
import Strong from 'components/Strong';
import {plurify} from 'utils/plurify';
import {PurchasePlanDisplayV2} from 'seats/containers/Checkout/common/types';
import {formatPriceSignOnly} from 'utils/priceFormat';
import {formatUserWorkspacePlan} from "seats/containers/Checkout/common/utils";

export function ComputedBillingPurchasePlans({plans, isGmailUser}: Readonly<{ plans: PurchasePlanDisplayV2[] | undefined; isGmailUser: boolean }>) {

  const getPlanDetailPhrase = (plan: PurchasePlanDisplayV2) => {
    if (isGmailUser) {
      return <Strong>{formatUserWorkspacePlan('PRO')} plan</Strong>
    }
    return (
        <>
          <Strong>{plan.quantity} {formatUserWorkspacePlan(plan.userPlan)}</Strong>
          {' '}
          {plurify(plan.quantity, 'seat', 'seats')}
        </>
    );
  }

  return (
    <>
      {!plans ? (
        <StyledDifferenceText className='planLoadingPlaceHolder'>
          <span>
            <Placeholder type="paragraph" size="extra-large" width={80}/>
          </span>
          <span>
            <Placeholder type="paragraph" size="extra-large" width={62}/>
          </span>
        </StyledDifferenceText>
      ) : (
        plans.map((plan) => (
          <ComputedBillingPurchasePlansItem key={`${plan.quantity}-${plan.currency}-${plan.userPlan}`}>
            <ComputedBillingPurchasePlansItemDetail>
              {getPlanDetailPhrase(plan)}
            </ComputedBillingPurchasePlansItemDetail>
            <ComputedBillingPurchasePlansItemDetail>
              <Strong>{formatPriceSignOnly(plan.totalAmount, plan.currency, 2)}</Strong>
            </ComputedBillingPurchasePlansItemDetail>
          </ComputedBillingPurchasePlansItem>
        ))
      )}
    </>
  );
}

