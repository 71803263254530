import styled from 'styled-components';
import {
  Button,
  Card as DefaultCard,
  FormControl as DefaultFormControl,
  Text as DefaultText,
} from '@scriptaddicts/yamm-ui-components';
import {from} from 'styles/media';

export const Container = styled.div`
    padding-top: 16px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const Card = styled(DefaultCard).attrs({
  variant: 'white',
  padding: 'big',
  flex: 'vertical',
})`
    gap: 24px;
    border-radius: 0px;
    padding: 24px;

    ${from.tablet} {
        border-radius: 6px;
        padding: 32px;
    }
`;

export const CardTitle = styled(DefaultText).attrs({
  size: 'extraLarge',
  width: 'bold',
})`
    line-height: 100% !important;
    color: ${({theme}) => theme.colors.gray800};
`;

export const FormControl = styled(DefaultFormControl)`
    max-width: 400px;
`;

export const RemoveButton = styled(Button).attrs({
  size: 'base',
  variant: 'danger',
})`
    max-width: 100%;

    > span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
