import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {postAssignUsers} from "seats/data/requests/workspaceManageUsers";
import {BackendError} from "model/error";
import {AssignUsersRequest, AssignUsersResponse} from "seats/data/requests/workspaceBilling";
import {queryClient} from "data/queryClient";

export const useAssignUsersToSeats = () => {
  return useMutation<
    AssignUsersResponse,
    AxiosError<BackendError>,
    { workspaceId: string; params: AssignUsersRequest }
  >(
    async ({workspaceId, params}) => {
      return postAssignUsers({workspaceId, params});
    },
    {
      onSuccess: async (_result, {workspaceId}) => {
        queryClient.invalidateQueries([
          'space',
          workspaceId,
          'subscriptions',
        ]);
        queryClient.invalidateQueries([
          'space',
          workspaceId,
          'members',
        ])
      }
    }
  );
};