import React from 'react';
import CheckoutLayout from 'seats/containers/Checkout/Layout';
import {ReactComponent as ArrowRight} from 'assets/images/icons/arrow-right.svg';
import {
  ManageSeatsPageButton,
  ManageSeatsPageFooterButtons,
  ManageSeatsPageTitle,
  BoldMessageText,
  CurrentSeatsQuantity,
  DropdownInnerContainer,
  DropdownOuterContainer,
  DropdownWrapper,
  MessageText,
  RemoveSeatsPageBody,
  RemoveSeatsPageBodyContent,
  RemoveSeatsPageBodyTop,
  RemoveSeatsPageContainer,
  RemoveSeatsPageSubtitleContainer,
  RemoveSeatsPageTitleContainer,
} from "seats/containers/Checkout/SeatsBillingRemoveSeats/styles";
import {useConnect} from "seats/containers/Checkout/SeatsBillingRemoveSeats/connect";
import {plurify} from "utils/plurify";
import {StyledStripeLogo} from "seats/containers/Checkout/common/styles";
import {Dropdown} from "seats/components/Dropdown";
import {IconWrapper} from "components/AccountDropdown/styles";
import {Alert, Text} from "@scriptaddicts/yamm-ui-components";
import {Stronger} from "seats/components/SeatStyledComponents/styles";
import LoadingScreen from "components/LoadingScreen";

const SeatsBillingRemoveSeats = () => {
  const {
    isLoading,
    workspaceName,
    isFetchingPaymentInfo,
    paymentInfo,
    seatQuantity,
    setSeatQuantity,
    saveClick,
    cancelClick,
    assignedSeats,
    currentTotalSeats,
    formattedMonthlyRate,
    nextPaymentAmount,
    nextPaymentDueDate,
    selectableSeatQuantities,
    isSubmitting,
    hasAvailableSeats
  } = useConnect();

  const seatsToBeRemoved = currentTotalSeats - seatQuantity;
  const seatsPhrase = !hasAvailableSeats ? 'seats' : plurify(seatsToBeRemoved, "seat", "seats");

  const removeSeatsPageButton = <>Remove {hasAvailableSeats ? (seatsToBeRemoved) : ''} {seatsPhrase}</>;

  if(isLoading){
    return <LoadingScreen />
  }

  return (<CheckoutLayout.Container>
    <CheckoutLayout.Main>
        <RemoveSeatsPageContainer>
          <RemoveSeatsPageTitleContainer>
            <ManageSeatsPageTitle>Remove unused seats for <Stronger>{workspaceName}</Stronger>
            </ManageSeatsPageTitle>
          </RemoveSeatsPageTitleContainer>

          {
            hasAvailableSeats && (
                                <RemoveSeatsPageSubtitleContainer>
                                  <MessageText>There {plurify(
                                    assignedSeats,
                                    "is",
                                    "are"
                                  )} <Stronger>{assignedSeats} of {currentTotalSeats} seats used</Stronger> in this space.</MessageText>
                                </RemoveSeatsPageSubtitleContainer>
                              )
          }

          {
            !hasAvailableSeats && (
                                 <Alert style={{alignSelf: "stretch"}} type="warning">
                                   <ul>
                                     <li><Text type="paragraph">
                                       There{' '}
                                       <Stronger>
                                         {plurify(
                                           currentTotalSeats,
                                           'is',
                                           'are'
                                         )} {currentTotalSeats} of {currentTotalSeats} {plurify(
                                         currentTotalSeats,
                                         'seat',
                                         'seats'
                                       )}
                                       </Stronger>
                                       {' '} used in this space.
                                     </Text>
                                     </li>
                                     <li>
                                       <Text type="paragraph">
                                         You need to <Stronger style={{textDecoration: "underline"}}>remove users</Stronger> before removing
                                         seats.
                                       </Text></li>
                                   </ul>
                                 </Alert>
                               )
          }
          <RemoveSeatsPageBody>
            <RemoveSeatsPageBodyContent>
              <RemoveSeatsPageBodyTop>
                <BoldMessageText>How many seats do you need?</BoldMessageText>
                <DropdownOuterContainer>
                  <DropdownInnerContainer>
                    <CurrentSeatsQuantity>{currentTotalSeats} {plurify(currentTotalSeats, 'seat', 'seats')}</CurrentSeatsQuantity>
                    <IconWrapper>
                      <ArrowRight/>
                    </IconWrapper>
                    <DropdownWrapper>
                      <Dropdown values={selectableSeatQuantities} fallbackValue={currentTotalSeats}
                                onChange={(value) => {
                                  setSeatQuantity(value)
                                }}/>
                    </DropdownWrapper>
                    <div>
                      <MessageText> {plurify(seatQuantity, "seat", "seats")} @ {formattedMonthlyRate} / seat / month</MessageText>
                    </div>
                  </DropdownInnerContainer>
                </DropdownOuterContainer>
              </RemoveSeatsPageBodyTop>
            </RemoveSeatsPageBodyContent>
          </RemoveSeatsPageBody>
          {(!isFetchingPaymentInfo && paymentInfo !== null && hasAvailableSeats) &&
           (<MessageText>
             You’re removing <Stronger>{seatsToBeRemoved}</Stronger> {plurify(seatsToBeRemoved, 'seat', 'seats')}, bringing you
             down to a total
             of <Stronger>{seatQuantity} {plurify(
             seatQuantity,
             'seat',
             'seats'
           )}</Stronger>.<br/>Your
             next payment of <Stronger>{nextPaymentAmount}</Stronger> will be
             due <Stronger>{nextPaymentDueDate}</Stronger>.<br/>You can <Stronger style={{textDecoration: "underline"}}>remove
             users</Stronger> if you’d like to remove more seats.
           </MessageText>)
          }
          <ManageSeatsPageFooterButtons>
            <ManageSeatsPageButton
              aria-label="save remove seats"
              size="base"
              onClick={saveClick}
              variant="danger"
              loading={isLoading || isSubmitting}
              disabled={isLoading || isSubmitting || isFetchingPaymentInfo || !hasAvailableSeats}
            >
              {removeSeatsPageButton}
            </ManageSeatsPageButton>
            <ManageSeatsPageButton
              aria-label="cancel remove seats"
              size="base"
              variant="secondary"
              onClick={cancelClick}
              disabled={isLoading || isFetchingPaymentInfo || isSubmitting}
            >
              Cancel
            </ManageSeatsPageButton>
          </ManageSeatsPageFooterButtons>
          <StyledStripeLogo/>
        </RemoveSeatsPageContainer>
    </CheckoutLayout.Main>
  </CheckoutLayout.Container>);
};

export default SeatsBillingRemoveSeats;
