import React from 'react';
import {Alert, Text} from '@scriptaddicts/yamm-ui-components';
import {CancelDowngradeButton, Stronger} from "seats/components/SeatStyledComponents/styles";
import {plurify} from 'utils/plurify';
import {formatLongMonthDayYear} from "utils/dates";
import {SeatsScheduledRemoval} from "seats/model/workspaceBilling";

interface ScheduledDowngradeAlertProps {
  userRole: string;
  scheduledRemoval?: SeatsScheduledRemoval;
  totalSeats: number;
  onCancelScheduledDowngrade: () => void;
}

const ScheduledDowngradeAlert: React.FC<ScheduledDowngradeAlertProps> = (
  {
    userRole,
    scheduledRemoval,
    totalSeats,
    onCancelScheduledDowngrade
  }) => {
  if (!scheduledRemoval) return null;

  const targetSeats = scheduledRemoval.targetSeatsQuantity;
  const removeDate = formatLongMonthDayYear(scheduledRemoval.removeSeatsAtTimestamp || 0);

  if (userRole === 'OWNER') {
    return (
      <Alert type="warning" style={{width: '100%'}}>
        <Text type="paragraph" size="large" width="regular">
          You have a <Stronger>scheduled downgrade to {targetSeats} {plurify(
          targetSeats,
          'seat',
          'seats'
        )}</Stronger> that
          will take effect on {removeDate}.
          <br/><CancelDowngradeButton onClick={onCancelScheduledDowngrade}>Cancel downgrade</CancelDowngradeButton>
        </Text>
      </Alert>
    );
  }

  if (userRole === 'ADMIN') {
    return (
      <Alert type="warning" style={{width: '100%'}}>
        <Text type="paragraph" size="large" width="regular">
          You have a <Stronger>scheduled downgrade
          from {totalSeats} to {targetSeats} {plurify(
            targetSeats,
            'seat',
            'seats'
          )}</Stronger> that
          will take effect on {removeDate}. If you want to add more users, please
          contact the space owner to cancel the downgrade.
        </Text>
      </Alert>
    );
  }

  return null;
};

export default ScheduledDowngradeAlert;