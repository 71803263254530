import React, { FC, memo, useCallback, useMemo } from 'react';
import { ReactComponent as BillingIcon } from 'assets/images/icons/clipboard.svg';
import { ReactComponent as ShuffleIcon } from 'assets/images/icons/shuffle.svg';
import { ReactComponent as ExitIcon } from 'assets/images/icons/logout.svg';
import testingVar from 'utils/testingVar';
import {
  DropdownContainer,
  DropdownList,
  Avatar,
} from '@scriptaddicts/yamm-ui-components';
import { useHistory } from 'react-router-dom';
import useChangeSelectedWorkspace from 'data/hooks/useChangeSelectedWorkspace';
import { OtherWorkspaceDecorator } from 'data/context/WorkspaceContext';
import { Props } from 'seats/components/AccountDropdown/types';
import {
  IconWrapper,
  Text,
  Chevron,
  AccountInfo,
  Name,
  Email,
  Trigger,
  DropdownListItem,
  AccountDropdownListItem,
} from 'seats/components/AccountDropdown/styles';
import useWorkspaceLocation from 'utils/router/useWorkspaceLocation';
import { useConnect } from 'seats/components/AccountDropdown/AccountDropDownSpace/connect';
import AccountDropdownSpace from 'seats/components/AccountDropdown/AccountDropDownSpace';
import {
  ActiveSpaceDropdownInfo,
  ActiveSpaceDropdownListItem,
} from 'seats/components/AccountDropdown/AccountDropDownSpace/styles';

const AccountDropdown: FC<Props> = ({
  id,
  name,
  email,
  photo,
  initials,
  onAccountSwitch,
  onLogout,
  shouldShowBillingButton,
  ...props
}) => {
  const { push } = useHistory();
  const { url } = useWorkspaceLocation();

  const onSwitch = useCallback(() => {
    onAccountSwitch();
  }, [onAccountSwitch]);

  const onBilling = useCallback(() => {
    push(`${url}/space/billing`);
  }, [push, url]);

  const { changeSelectedWorkspaceThenNavigate } = useChangeSelectedWorkspace();

  const { activeWorkspace, hasSupportGivenSubscription, hasOfflineSubscription } = useConnect();

  const isSelectableSpace = useMemo(() => {
    if (activeWorkspace === undefined) return false;
    const otherWorkspaceDecorator = new OtherWorkspaceDecorator(
      activeWorkspace,
    );
    return otherWorkspaceDecorator.hasAccessToUsers();
  }, [activeWorkspace]);

  return (
    <DropdownContainer {...props}>
      <Trigger
        aria-controls="account-menu"
        aria-haspopup="true"
        data-testid="trigger"
        data-test-id={testingVar('account-dropdown')}
      >
        <Avatar src={photo} initials={initials} identifier={id} />
      </Trigger>
      <DropdownList
        style={{
          width: '334px',
          marginLeft: '-302px',
          marginTop: '14px !important',
          border: 'none',
        }}
        id="account-menu"
      >
        <AccountDropdownListItem>
          <Avatar src={photo} initials={initials} identifier={id} />
          <AccountInfo>
            <Name>{name}</Name>
            <Email data-test-id={testingVar('user-email')}>{email}</Email>
          </AccountInfo>
        </AccountDropdownListItem>
        {isSelectableSpace ? (
          <ActiveSpaceDropdownListItem
            onClick={() => {
              changeSelectedWorkspaceThenNavigate(activeWorkspace?.id);
            }}
          >
            <AccountDropdownSpace
              activeWorkspace={activeWorkspace}
              showChevron
            />
          </ActiveSpaceDropdownListItem>
        ) : (
          <ActiveSpaceDropdownInfo>
            <AccountDropdownSpace
              activeWorkspace={activeWorkspace}
              showChevron={false}
            />
          </ActiveSpaceDropdownInfo>
        )}

        {shouldShowBillingButton  && !hasSupportGivenSubscription && !hasOfflineSubscription && (
          <DropdownListItem onClick={onBilling} data-testid="billing">
            <IconWrapper>
              <BillingIcon />
            </IconWrapper>
            <Text>Manage billing</Text>
            <Chevron />
          </DropdownListItem>
        )}
        <DropdownListItem
          data-test-id={testingVar('submit-switch')}
          onClick={onSwitch}
          data-testid="switch"
        >
          <IconWrapper>
            <ShuffleIcon />
          </IconWrapper>
          <Text>Switch account</Text>
          <Chevron />
        </DropdownListItem>
        <DropdownListItem
          data-test-id={testingVar('submit-logout')}
          onClick={onLogout}
          data-testid="logout"
        >
          <IconWrapper>
            <ExitIcon />
          </IconWrapper>
          <Text>Log out</Text>
          <Chevron />
        </DropdownListItem>
      </DropdownList>
    </DropdownContainer>
  );
};

export default memo(AccountDropdown);
