import {
  InputTag,
  useAutocompleteTagInput,
} from '@scriptaddicts/yamm-ui-components';
import {useMe} from 'data/hooks/useMe';

import {
  Dispatch,
  DispatchWithoutAction,
  useCallback, useEffect,
  useMemo,
  useState,
} from 'react';
import {from, useMediaQuery} from 'styles/media';
import useSubscriptionSeat from "seats/data/hooks/useSubscriptionSeat";
import {useAssignUsersToSeats} from "seats/data/hooks/useAssignUsersToSeats";
import {useSuggestUser} from "data/hooks/useUsers";
import {plurify} from "utils/plurify";
import { useWorkspaceContext } from 'data/context/WorkspaceContext';

export const useConnect = ({
  onClose,
  onSuccess,
}: {
  onClose: DispatchWithoutAction;
  onSuccess?: Dispatch<{ addedUsers: number }>;
}) => {
  const [userEmailTags, setUserEmailTags] = useState<InputTag[]>([]);

  const {
    tagInput: {tags: userEmailsToAssign, setTags: setUserEmailsToAssign, ...tagInput},
    autocomplete: {setValue, search, ...autocomplete},
  } = useAutocompleteTagInput({
                                autocomplete: {openOn: 2, timeout: 500},
                              });

  const {data: me} = useMe();
  const {id: workspaceId, user, isLoading: isLoadingWorkspace} = useWorkspaceContext();
  const {subscriptionSeat, isLoadingSubscriptionSeat} = useSubscriptionSeat(workspaceId, user?.role);

  const { data: userSuggestion } = useSuggestUser(
    {
      workspaceId,
      searchKeyword: search,
    },
    !!me && !me.isPersonal,
  );

  const {
    mutate: assignUserMutate,
    isSuccess: isAssignUserSuccess,
    isLoading: isAssignUserLoading,
    data: assignUserResult,
    reset: resetAssignUser,
  } = useAssignUsersToSeats();

  const errors = useMemo(
    () =>
      (assignUserResult?.results ?? []).reduce(
        (map, {userEmail, errorDetails}) => ({
          ...map,
          ...(userEmail && userEmailsToAssign.includes(userEmail)
            ? {
              [userEmail]: {
                tip: errorDetails,
                variant: errorDetails ? 'error' : undefined,
              },
            }
            : {}),
        }),
        [] as Record<string, any>,
      ),
    [assignUserResult, userEmailsToAssign],
  );

  // Validate on email addresses change
  useEffect(() => {
    setUserEmailTags(
      userEmailsToAssign.map((member) => errors[member] ? {text: member, ...errors[member]} : {text: member},
      ),
    );
  }, [userEmailsToAssign, errors]);

  const hasError = useMemo(
    () => {
      const invalidEmailsCount =
        Object.entries(errors).filter(([, value]) => value.variant !== undefined).length;

      let partialMessage;
      if (subscriptionSeat.availableSeats < userEmailTags.length) {
        const isOrAre = `${plurify(
          subscriptionSeat.availableSeats,
          'is',
          'are'
        )}`;
        const seatOrSeats = `${plurify(
          subscriptionSeat.availableSeats,
          'seat',
          'seats'
        )}`;
        const userOrUsers = `${plurify(
          subscriptionSeat.availableSeats,
          'user',
          'users'
        )}`;
        partialMessage = `There ${isOrAre} ${subscriptionSeat.availableSeats} available ${seatOrSeats} in this space so you can add ${subscriptionSeat.availableSeats} ${userOrUsers}. To add more users you need to add more seats to the space first.`
      } else if (invalidEmailsCount > 0) {
        partialMessage = "We can't add some email addresses. Hover over them to know why."
      }
      return ({
        partial: partialMessage,
        all: !!userEmailTags.length && invalidEmailsCount === userEmailTags.length,
      });
    },
    [errors, userEmailTags, subscriptionSeat],
  );

  const assignCallback = useCallback(() => {
    const userEmails = userEmailsToAssign; // .filter((email) => !errors[email]);
    if (!userEmails.length || !workspaceId) {
      return;
    }
    assignUserMutate({
                       workspaceId,
                       params: {
                         userEmails
                       },
                     },);
  }, [assignUserMutate, workspaceId, userEmailsToAssign, errors]);

  const resetForm = useCallback(() => {
    setUserEmailsToAssign([]);
    setValue('');
  }, [setUserEmailsToAssign, setValue]);

  useEffect(() => {
    if (isAssignUserSuccess && assignUserResult?.success && hasError.partial === undefined && onSuccess) {
      onSuccess({addedUsers: userEmailTags.length});
      resetAssignUser();
      onClose();
      resetForm();
    }
  }, [hasError.partial, isAssignUserSuccess, assignUserResult]);

  const isMobile = !useMediaQuery(from.tablet);


  return {
    isLoading: isAssignUserLoading || isLoadingSubscriptionSeat || isLoadingWorkspace,
    resetForm,
    domain: me?.domain,
    search: {
      items: userSuggestion,
      ...autocomplete,
    },
    tags: {
      userEmailTags,
      hasError,
      ...tagInput,
    },
    assign: {
      onClick: assignCallback,
      count: userEmailsToAssign.length,
      disabled: !userEmailsToAssign.length && !search,
    },
    isMobile,
    availableSeats: subscriptionSeat.availableSeats,
  };
};
