import React, {useEffect, useState} from "react";
import {ReactComponent as ChevronDown} from 'assets/images/icons/chevron-down.svg';
import {ReactComponent as CheckIcon} from 'assets/images/icons/check-seat.svg';
import {ActionContainer} from "containers/Main/Workspaces/WorkspaceDropdown/styles";
import {DropdownContainer} from "@scriptaddicts/yamm-ui-components";
import {
  SeatDropdownList,
  SeatDropdownListItem,
  SeatDropdownListItemText,
  Trigger
} from "seats/components/Dropdown/styles";

interface DropdownProps {
  values: number[];
  onChange: (value: number) => void;
  fallbackValue: number;
}

export const Dropdown = ({values, onChange, fallbackValue}: DropdownProps) => {
  const [value, setValue] = useState(values[0]);
  const [selectionEnabled, setSelectionEnabled] = useState(true);

  useEffect(() => {
    onChange(value);
  }, [value]);

  useEffect(() => {
    if(values.length === 0){
      values.push(fallbackValue);
      setValue(fallbackValue);
      setSelectionEnabled(false);
    }
  }, [values]);


  return (
    <DropdownContainer style={ selectionEnabled ? {} : {opacity: "0.5", pointerEvents: "none"}}>
      <Trigger aria-controls="remove-seats">
        {value}
        <ActionContainer><ChevronDown/></ActionContainer>
      </Trigger>
      <SeatDropdownList
        id="remove-seats"
      >
        {values.map((v) => (
          <SeatDropdownListItem
            key={v} value={v} onClick={() => {
            setValue(v)
          }}>
            <CheckIcon style={{visibility: value === v ? "visible" : "hidden"}} width="16px" height="16px"/>
            <SeatDropdownListItemText>{v}</SeatDropdownListItemText>
          </SeatDropdownListItem>

        ))}
      </SeatDropdownList>
    </DropdownContainer>

  );
};