import {useMemo} from 'react';
import { useFormikContext} from 'formik';
import {useWorkspaceContext} from 'data/context/WorkspaceContext';
import {SeatCheckoutFormValues} from 'seats/data/context/CheckoutContext/types';
import {getPurchasePlansDisplayV2} from 'seats/containers/Checkout/common/utils';
import {usePrices} from 'data/hooks/usePaymentPlans';
import {useSeatCheckoutContext} from "seats/data/context/CheckoutContext";
import {
  useComputeStripePaymentInfoV2,
  useWorkspaceActiveSubscriptionV2
} from "seats/data/hooks/useSeatsWorkspaceBilling";
import {
  getComputeParamsForExistingSubscription, getDefaultComputeParams
} from "seats/containers/Checkout/BillingDetailsSidebar/utils";
import {useMe} from "data/hooks/useMe";
import {PaidUserPlan} from "seats/data/requests/workspaceBilling";
import {getAvailablePlanForEmail} from "seats/utils/workspaceBillingUtil";

export const useConnect = ( ) => {
  const { id: workspaceId, isLoading, user } = useWorkspaceContext();
  const { values, submitForm } = useFormikContext<SeatCheckoutFormValues>();
  const {plan, billingInterval, seatQuantity} = useSeatCheckoutContext();
  const { paymentPlans } = usePrices();

  const {
    data: activeSubscription,
    isLoading: isLoadingActiveSubscription,
  } = useWorkspaceActiveSubscriptionV2({workspaceId, userRole: user?.role});

  const {data: userData, isGmailUser, isLoading: isLoadingUser} = useMe();

  const paidUserPlan: PaidUserPlan | undefined =  useMemo(() => {
    if (userData?.email && !isLoadingUser) {
      return getAvailablePlanForEmail(userData.email);
    }
    return undefined;
  }, [userData?.email, isLoadingUser]);


  const computeStripePaymentInfoRequestPayload = useMemo(() => {
    if (!paidUserPlan) {
      return undefined;
    }
    if (activeSubscription) {
      return getComputeParamsForExistingSubscription(
        activeSubscription,
        seatQuantity,
      );
    }
    return getDefaultComputeParams(billingInterval, seatQuantity, paidUserPlan,values.country,
      values.isBusinessPurchase ? 'B2B' : 'B2C');
  }, [activeSubscription, billingInterval, plan, seatQuantity, paidUserPlan,values.country,
    values.isBusinessPurchase]);

  const {
    data: paymentInfo,
    isFetching: isFetchingPaymentInfo,
  } = useComputeStripePaymentInfoV2(
    isLoading || isLoadingActiveSubscription ? undefined : workspaceId,
    computeStripePaymentInfoRequestPayload,
  );

  const purchasePlansDisplay = useMemo(() => {
    if (paymentPlans && paymentInfo?.stripePlans) {
      return getPurchasePlansDisplayV2(
        paymentInfo.stripePlans,
        paymentPlans,
        paymentInfo.currency,
      );
    }
    return undefined;
  }, [paymentInfo, paymentPlans]);

  return {
    billingInterval,
    paymentInfo,
    showTaxesInfo: paymentInfo?.currency === 'EUR',
    isFetchingPaymentInfo: isFetchingPaymentInfo || isLoadingUser,
    submitForm,
    purchasePlansDisplay,
    plan,
    isGmailUser
  };
};
