export const PLAN = 'plan';
export const INTERVAL = 'interval';
export const SHOW_PLANS = 'show-plans';
export const CHECKOUT = 'checkout';
export const FROM = 'from';
export const TO = 'to';
export const FROM_INTERVAL = 'fromInterval';
export const NEW_GMAIL_SEATS = 'gmailSeats';
export const NEW_WORKSPACE_SEATS = 'workspaceSeats';
export const SEATS_ADDED = 'seatsAdded';
export const SEATS_REMOVED = 'seatsRemoved';
