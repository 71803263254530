import React from 'react';
import {Alert, Text} from '@scriptaddicts/yamm-ui-components';
import {BillingAlertsProps} from 'seats/containers/Main/Workspaces/Billing/BillingAlerts/types';

const BillingAlerts = ({
                         canceledScheduledDowngrade,
                       }: BillingAlertsProps) => {
  return (
    <>
      {!!canceledScheduledDowngrade && (
        <Alert type="success" stroke="300">
          <Text type="paragraph" size="large" width="semiBold">
            You successfully cancelled the previously scheduled downgrade.
          </Text>
        </Alert>
      )}
    </>
  );
};

export default React.memo(BillingAlerts);
