import React from 'react';
import CheckoutLayout from 'seats/containers/Checkout/Layout';
import {Placeholder} from "@scriptaddicts/yamm-ui-components";
import {
  AdditionalSeatsPageBody,
  AdditionalSeatsPageBodyBottom,
  AdditionalSeatsPageBodyBottomContent,
  AdditionalSeatsPageBodyContent,
  AdditionalSeatsPageBodyTop, AdditionalSeatsPageButton,
  AdditionalSeatsPageContainer,
  AdditionalSeatsPageFooterButtons,
  AdditionalSeatsPageSubtitleContainer,
  AdditionalSeatsPageTitle,
  AdditionalSeatsPageTitleContainer,
  AdditionalSeatsWithPrice,
  AdditionalSeatsWithPriceContent,
  AdditionalSeatsWithPriceContentBody,
  BoldMessageText,
  MessageText,
} from "seats/containers/Checkout/SeatsBillingAddSeats/styles";
import {useConnect} from "seats/containers/Checkout/SeatsBillingAddSeats/connect";
import {plurify} from "utils/plurify";
import {
  ComputedBillingPurchasePlansItemDivider,
  ComputedBillingPurchasePlansItemDividerLine, StyledStripeLogo
} from "seats/containers/Checkout/common/styles";
import {NumberInputSpinner} from "seats/components/NumberInputSpinner";
import {Stronger} from "seats/components/SeatStyledComponents/styles";
import LoadingScreen from "components/LoadingScreen";
import {formatUserWorkspacePlan} from "seats/containers/Checkout/common/utils";


const SeatsBillingAddSeats = () => {
  const {
    isLoading,
    workspaceName,
    isFetchingPaymentInfo,
    paymentInfo,
    seatQuantity,
    setSeatQuantity,
    addClick,
    cancelClick,
    maxSeatsLeftToAdd,
    currentTotalSeats,
    totalSeatsAfterAddition,
    formattedMonthlyRate,
    proratedAmount,
    nextPaymentAmount,
    nextPaymentDueDate,
    proratedDays,
    isSubmitting,
    userPlanType,
    isSeatQuantityReady,
    setIsSeatQuantityReady,
  } = useConnect();

  const additionalSeatsPhrase = plurify(seatQuantity, "seat", "seats");
  const currentSeatsPhrase = plurify(currentTotalSeats, "seat", "seats");
  const newSeatsPhrase = plurify(totalSeatsAfterAddition, "seat", "seats");


  if(isLoading){
    return <LoadingScreen />
  }
  return (<CheckoutLayout.Container>
    <CheckoutLayout.Main>
        <AdditionalSeatsPageContainer>
          <AdditionalSeatsPageTitleContainer>
            <AdditionalSeatsPageTitle>Add seats for <Stronger>{workspaceName}</Stronger>
            </AdditionalSeatsPageTitle>
          </AdditionalSeatsPageTitleContainer>
          <AdditionalSeatsPageSubtitleContainer>
            <MessageText>There {plurify(
              currentTotalSeats,
              "is",
              "are"
            )} currently <Stronger>{currentTotalSeats} {formatUserWorkspacePlan(userPlanType)} {currentSeatsPhrase}</Stronger> for your
              space.</MessageText>
          </AdditionalSeatsPageSubtitleContainer>
          <AdditionalSeatsPageBody>
            <AdditionalSeatsPageBodyContent>
              <AdditionalSeatsPageBodyTop>
                <BoldMessageText>How many seats do you want to add?</BoldMessageText>
                <AdditionalSeatsWithPrice>
                  <AdditionalSeatsWithPriceContent>
                    <AdditionalSeatsWithPriceContentBody>
                      <NumberInputSpinner minValue={1} maxValue={maxSeatsLeftToAdd} initialValue={seatQuantity}
                                          onChange={(val) => {
                                            setIsSeatQuantityReady(true)
                                            setSeatQuantity(val);
                                          }}
                                          onInvalid={() => {
                                            setIsSeatQuantityReady(false);
                                          }}>
                        <MessageText>new {additionalSeatsPhrase} @ {formattedMonthlyRate}{' '}/{' '}seat{' '}/{' '}month,
                          prorated
                          for {proratedDays}{' '} days</MessageText>
                      </NumberInputSpinner>
                    </AdditionalSeatsWithPriceContentBody>
                  </AdditionalSeatsWithPriceContent>
                  {isFetchingPaymentInfo || !paymentInfo ?
                    (<Placeholder type="paragraph" size="extra-large" width={80}/>) :
                    <BoldMessageText>{proratedAmount}</BoldMessageText>}
                </AdditionalSeatsWithPrice>
              </AdditionalSeatsPageBodyTop>
              <ComputedBillingPurchasePlansItemDivider>
                <ComputedBillingPurchasePlansItemDividerLine/>
              </ComputedBillingPurchasePlansItemDivider>
              <AdditionalSeatsPageBodyBottom>
                <AdditionalSeatsPageBodyBottomContent>
                  <BoldMessageText>Total</BoldMessageText>
                  <BoldMessageText>
                    {isFetchingPaymentInfo || !paymentInfo ?
                      (<Placeholder type="span" size="extra-large" width={80}/>) : proratedAmount}
                  </BoldMessageText>
                </AdditionalSeatsPageBodyBottomContent>
              </AdditionalSeatsPageBodyBottom>
            </AdditionalSeatsPageBodyContent>
          </AdditionalSeatsPageBody>
          {(!isFetchingPaymentInfo && paymentInfo !== undefined) &&
           (<MessageText style={{alignSelf: "stretch"}} type="paragraph">You will be
             charged <Stronger>{proratedAmount}</Stronger> today
             for <Stronger>{seatQuantity}</Stronger> additional <Stronger>{additionalSeatsPhrase}</Stronger> bringing
             you
             up to a total of <Stronger>{totalSeatsAfterAddition} {newSeatsPhrase}</Stronger>.<br/>Your next payment
             of <Stronger>{nextPaymentAmount}</Stronger> will be due <Stronger>{nextPaymentDueDate}</Stronger>.
           </MessageText>)
          }
          <AdditionalSeatsPageFooterButtons>
            <AdditionalSeatsPageButton
              aria-label="purchase additional seats"
              size="base"
              onClick={addClick}
              loading={isLoading || isSubmitting}
              disabled={isLoading || isSubmitting || isFetchingPaymentInfo || !isSeatQuantityReady}
            >
              Add {seatQuantity} {additionalSeatsPhrase}
            </AdditionalSeatsPageButton>
            <AdditionalSeatsPageButton
              aria-label="cancel additional seats purchase"
              size="base"
              variant="secondary"
              onClick={cancelClick}
              disabled={isLoading || isFetchingPaymentInfo || isSubmitting}
            >
              Cancel
            </AdditionalSeatsPageButton>
          </AdditionalSeatsPageFooterButtons>
          <StyledStripeLogo/>
        </AdditionalSeatsPageContainer>
    </CheckoutLayout.Main>
  </CheckoutLayout.Container>);
};

export default SeatsBillingAddSeats;
