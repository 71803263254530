import {Color, Text, Tooltip} from '@scriptaddicts/yamm-ui-components';
import React, {useCallback, useMemo} from 'react';
import { useHistory } from 'react-router-dom';
import { plurify } from 'utils/plurify';
import { PageRestrictions } from 'data/hooks/restrictions/useRestrictions';
import CompleteFeatureList from 'containers/Main/Workspaces/Billing/CompleteFeatureList';
import {
  ComputedBillingPurchasePlansItemDivider,
  ComputedBillingPurchasePlansItemDividerLine,
} from 'seats/containers/Checkout/common/styles';
import { getRecipientsQuotaPerDay } from 'utils/workspace';
import {MAX_USERS_PER_SPACE} from "data/constants";
import {
  StyledListItem,
  LinksSection,
  SeatsUsedContainer,
  SeatsStatusTextContainer,
  SeatsStatusContainer,
  Of,
  Loading,
  Progress,
  Progressbar,
  SeatsUsed,
  StyledFeatureContainer,
  StyledFeatureItem,
} from 'seats/containers/Main/Workspaces/Billing/CurrentPlan/styles';
import {StyledSupportLink} from "seats/components/SeatStyledComponents/styles";
import {SubscriptionSeat} from "seats/data/hooks/useSubscriptionSeat";

export type PlanSeatDetailsProps = {
  assignedSeats: number;
  totalSeats: number;
  workspaceUrlPrefix: string;
  pageRestrictions: PageRestrictions;
  isGmailUser: boolean | undefined;
  subscriptionSeat: SubscriptionSeat;
};

const SupportLinkAttrs = {
  href: 'https://support.yet-another-mail-merge.com/hc/en-us/requests/new',
  rel: 'noreferrer',
  target: '_blank',
};

const PlanSeatDetails = ({
  assignedSeats,
  totalSeats,
  workspaceUrlPrefix,
  pageRestrictions,
  isGmailUser,
  subscriptionSeat
}: PlanSeatDetailsProps) => {
  const { push } = useHistory();

  const isSeatsLimitReached = useMemo(() => {
    return MAX_USERS_PER_SPACE === totalSeats;
  }, [totalSeats]);

  const addToolTip = useMemo(() => {
    if (subscriptionSeat.hasScheduledRemoval()) {
      return <Text size="small">If you want to add more seats, you need to<br/>cancel the downgrade first.</Text>;
    }
    if (isSeatsLimitReached) {
      return <Text size="small">You have reached the maximum number of seats. If you want to have more than 100 seats,
        please <StyledSupportLink {...SupportLinkAttrs}>Contact Support</StyledSupportLink></Text>;
    }
    return <></>
  }, [subscriptionSeat, isSeatsLimitReached]);

  const shouldDisplayAddTooltip = subscriptionSeat.hasScheduledRemoval() || isSeatsLimitReached;

  const removeToolTip = useMemo(() => {
    if (subscriptionSeat.hasScheduledRemoval()) {
      return <Text size="small">If you want to remove more seats, you need to<br/>cancel the downgrade first.</Text>;
    }
    return <></>
  }, [subscriptionSeat]);

  const shouldDisplayRemoveTooltip = subscriptionSeat.hasScheduledRemoval();

  const onAddSeats = useCallback(() => {
    if (!shouldDisplayAddTooltip) {
      push(`${workspaceUrlPrefix}/checkout/add-seats`);
    }
  }, [shouldDisplayAddTooltip, push]);

  const onRemoveUnusedSeats = useCallback(() => {
    if (!shouldDisplayRemoveTooltip) {
      push(`${workspaceUrlPrefix}/checkout/remove-seats`);
    }
  }, [shouldDisplayAddTooltip, push]);

  const onManageUsers = () => {
    push(`${workspaceUrlPrefix}/space/users`);
  };

  const isUserPageRestricted =
    pageRestrictions.ready && pageRestrictions.userPage;
    const features = isGmailUser ? [
      '•   All basic features included',
      '•   Scheduling',
      `•   Up to ${getRecipientsQuotaPerDay('PRO_PERSONAL')} recipients / day`,
      '•   Priority support',
    ] :
    [
      '•   All basic features included',
      '•   User management',
      '•   Scheduling',
      `•   Up to ${getRecipientsQuotaPerDay('PRO')?.toLocaleString("en-US")} recipients / day`,
      '•   Priority support',
    ];
  const assignedSeatsPercentage = `${((assignedSeats / totalSeats) * 100).toFixed(0)}%`;
  return (
    <>
      <StyledFeatureContainer>{features.map((feature) => (
        <StyledFeatureItem key={feature}>
          <Text size="base">{feature}</Text>
          </StyledFeatureItem>
      ))}</StyledFeatureContainer>
      <CompleteFeatureList isLegacyPlanType={false} />
      {
        !isGmailUser && (
           <>
             <ComputedBillingPurchasePlansItemDivider>
               <ComputedBillingPurchasePlansItemDividerLine/>
             </ComputedBillingPurchasePlansItemDivider>
             <SeatsStatusContainer>
               <SeatsStatusTextContainer>
                 <SeatsUsedContainer>
                   <Of>{`${assignedSeats} of ${totalSeats} `}</Of>
                   <SeatsUsed>{plurify(totalSeats, 'seat', 'seats')} used</SeatsUsed>
                 </SeatsUsedContainer>
               </SeatsStatusTextContainer>
               <Loading>
                 <Progressbar>
                   <Progress style={{width: assignedSeatsPercentage}}/>
                 </Progressbar>
               </Loading>
             </SeatsStatusContainer>
             <LinksSection>
               {!isUserPageRestricted && (
                 <StyledListItem onClick={onManageUsers}>
                   <Color variant="info">Manage users</Color>
                 </StyledListItem>
               )}
               <StyledListItem onClick={onAddSeats}>
                 <Tooltip
                     maxWidth="auto"
                     placement="top"
                     key="add-seats"
                     allowHTML
                     interactive
                     tip={addToolTip}
                     disabled={!shouldDisplayAddTooltip}
                 >
                   <Color variant="info" style={{opacity: shouldDisplayAddTooltip ? 0.5 : 1}}>Add seats</Color>
                 </Tooltip>
               </StyledListItem>
               <StyledListItem onClick={onRemoveUnusedSeats}>
                 <Tooltip
                   maxWidth="auto"
                   placement="bottom"
                   key="remove-seats"
                   allowHTML
                   interactive
                   tip={removeToolTip}
                   disabled={!shouldDisplayRemoveTooltip}
                 >
                   <Color variant="info" style={{opacity: shouldDisplayRemoveTooltip ? 0.5 : 1}}>Remove unused seats</Color>
                 </Tooltip>
               </StyledListItem>
             </LinksSection>
           </>
       )
      }
    </>
  );
};

export default PlanSeatDetails;
