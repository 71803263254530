import {useCallback, useEffect, useMemo, useState} from 'react';
import {captureException} from '@sentry/react';
import {useHistory, useLocation} from 'react-router-dom';
import {logError,} from 'utils/userJourneyLogging';
import {useSeatCheckoutContext} from 'seats/data/context/CheckoutContext';
import {useWorkspaceContext} from 'data/context/WorkspaceContext';
import {getErrorMessage,} from 'seats/containers/Checkout/SeatsBillingInformation/utils';
import useWorkspaceLocation from 'utils/router/useWorkspaceLocation';
import {getComputeParamsForExistingSubscription,} from "seats/containers/Checkout/BillingDetailsSidebar/utils";
import {
  useComputeStripePaymentInfoV2,
  useRemoveSeats,
  useWorkspaceActiveSubscriptionV2
} from "seats/data/hooks/useSeatsWorkspaceBilling";
import {format} from "date-fns";
import {formatPriceSignOnly} from "utils/priceFormat";
import {ReduceSeatsRequest} from "seats/data/requests/workspaceBilling";
import useSubscriptionSeat from "seats/data/hooks/useSubscriptionSeat";
import {SEATS_REMOVED} from "seats/data/queryParams";
import {queryClient} from "data/queryClient";
import {useRestrictAddRemoveSeatsPage} from "seats/data/hooks/useRestrictAddRemoveSeatsPage";

export const useConnect = () => {
  const {
    plan, seatQuantity, setSeatQuantity, isLoading: isLoadingCheckoutContext,
  } = useSeatCheckoutContext();
  const {goBack, push} = useHistory();
  const {pathname} = useWorkspaceLocation();
  const {search} = useLocation();
  const {
    id: workspaceId, details: workspaceDetails, isLoading: isLoadingWorkspace, urlPrefix, user
  } = useWorkspaceContext();

  const {
    data: activeSubscription, isLoading: isLoadingActiveSubscription
  } = useWorkspaceActiveSubscriptionV2({workspaceId, userRole: user?.role});

  const {isRestrictAddRemoveSeatsPageInProgress} = useRestrictAddRemoveSeatsPage(workspaceId, urlPrefix, user?.role);

  const subscriptionId = useMemo(() => {
    return activeSubscription?.subscriptionId
  }, [activeSubscription])

  const {subscriptionSeat} = useSubscriptionSeat(workspaceId, user?.role);

  const [, setSubmitError] = useState<string | undefined>();

  const seatsToBeRemoved = useMemo(() => {
    return subscriptionSeat.totalSeats - seatQuantity;
  }, [subscriptionSeat.totalSeats, seatQuantity]);

  const computeStripePaymentInfoRequestPayload = useMemo(() => {
    if (!activeSubscription) {
      return undefined;
    }
    return getComputeParamsForExistingSubscription(activeSubscription, -seatsToBeRemoved);
  }, [activeSubscription, seatsToBeRemoved]);

  const {
    data: paymentInfo, isFetching: isFetchingPaymentInfo,
  } = useComputeStripePaymentInfoV2(
    isLoadingWorkspace || isLoadingActiveSubscription? undefined : workspaceId,
    computeStripePaymentInfoRequestPayload,
    subscriptionSeat.totalSeats
  );

  const {
    mutateAsync, isLoading: isSubmitting,
  } = useRemoveSeats();

  const monthlyRate = useMemo(() => {
    return (activeSubscription?.subscriptionPaymentPlans[0]?.tiers[0]?.unitAmount ?? 0) / 12;
  }, [activeSubscription]);

  const paymentPlanId = useMemo(() => {
    return activeSubscription?.subscriptionPaymentPlans[0]?.paymentPlanId as string
  }, [activeSubscription])

  useEffect(() => {
    if (!isLoadingActiveSubscription && !activeSubscription) {
      goBack();
    }
  }, [activeSubscription, isLoadingActiveSubscription]);

  const saveClick = useCallback(
    async () => {
      setSubmitError(undefined);

      if (!workspaceId || !subscriptionId || !paymentPlanId) {
        return;
      }

      try {
        const reduceSeatsRequest: ReduceSeatsRequest = {
          subscriptionPlanSeats: [
            {
              paymentPlanId,
              targetSeatsQuantity: seatQuantity,
            }
          ]
        };

        const data = await mutateAsync({workspaceId, subscriptionId, ...reduceSeatsRequest});

        if (data) {
          queryClient.invalidateQueries([
            "space",
            workspaceId,
            "subscriptions",
          ]);

          push(`${urlPrefix}/space/billing?checkout=success&${SEATS_REMOVED}=${seatsToBeRemoved}`);
        }
      } catch (e) {
        window.scrollTo(0, 0);
        captureException(e);
        const {isField, key} = getErrorMessage(e);

        if (!isField) {
          setSubmitError(key);
        }

        logError({
          errorType: key,
          pagePath: pathname,
          search,
          workspaceId,
          selectedPlan: plan,
        });
      }
    },
    [
      workspaceId,
      subscriptionId,
      paymentPlanId,
      seatQuantity,
      mutateAsync,
      push,
      urlPrefix,
      subscriptionSeat,
      pathname,
      search,
      plan
    ]
  );

  const selectableSeatQuantities = useMemo(() => {
    const quantities = [];
    for (let i = subscriptionSeat.totalSeats - 1; i >= subscriptionSeat.assignedSeats; i -= 1) {
      quantities.push(i);
    }
    return quantities;
  }, [subscriptionSeat.totalSeats, subscriptionSeat.assignedSeats]);

  const currency = useMemo(() => {
    return activeSubscription?.currency ?? 'USD';
  }, [activeSubscription]);

  const formattedMonthlyRate = useMemo(() => {
    return formatPriceSignOnly(monthlyRate, currency, 2);
  }, [monthlyRate, currency]);

  const nextPaymentAmount = useMemo(() => {
    return formatPriceSignOnly(paymentInfo?.totalAmount ?? 0, currency, 2);
  }, [paymentInfo?.totalAmount, currency]);

  const nextPaymentDueDate = useMemo(() => {
    return format(new Date(activeSubscription?.endTimestamp ?? 0), "dd MMMM yyyy");
  }, [activeSubscription?.endTimestamp]);

  return {
    isLoading: isLoadingWorkspace || isLoadingActiveSubscription || isLoadingCheckoutContext || isRestrictAddRemoveSeatsPageInProgress,
    workspaceName: workspaceDetails?.name,
    isFetchingPaymentInfo,
    paymentInfo,
    seatQuantity,
    setSeatQuantity,
    saveClick,
    cancelClick: () => {
      goBack();
    },
    assignedSeats: subscriptionSeat.assignedSeats,
    currentTotalSeats: subscriptionSeat.totalSeats,
    formattedMonthlyRate,
    nextPaymentAmount,
    nextPaymentDueDate,
    selectableSeatQuantities,
    isSubmitting,
    hasAvailableSeats: subscriptionSeat.hasAvailableSeats(),
  };
}