import {
  CHECKOUT,
  FROM,
  TO,
  NEW_WORKSPACE_SEATS,
  NEW_GMAIL_SEATS,
  SEATS_REMOVED,
  SEATS_ADDED
} from 'seats/data/queryParams';
import {useEffect, useMemo} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useWorkspaceContext} from "data/context/WorkspaceContext";
import {useMe} from "data/hooks/useMe";

export const CHECKOUT_TYPE = {
  NONE: -1,
  NEW_PLAN_PURCHASE: 1,
  WORKSPACE_SEATS_ADDED: 2,
  WORKSPACE_SEATS_REMOVED: 3,
};

const getNumber = (value: string | null): number => {
  if (!value) return 0;
  return parseInt(value, 10);
};

export type CheckoutResult = {
  checkoutType: number;
  newGmailPaidSeats: {
    quantity: number,
    plan: 'PRO',
  };
  newWorkspaceSeats: {
    quantity: number,
    plan: 'PRO',
  },
  manageUsers: () => void;
  isGmail: boolean;
  quantityChange : number
};


export const useConnect = (): CheckoutResult => {
  const {search} = useLocation();
  const {urlPrefix, isLoading: isLoadingWorkspace, user} = useWorkspaceContext();
  const {push, replace} = useHistory();
  const {isGmailUser} = useMe();
  const queryParams = new URLSearchParams(search);

useEffect(() => {
  if (queryParams.get(CHECKOUT) === 'success' && !isLoadingWorkspace && user?.plan === 'FREE') {
    replace(`${urlPrefix}/space/billing`);
  }
}, [queryParams, isLoadingWorkspace, user, replace, urlPrefix]);

  return useMemo(() => {
    const result: CheckoutResult = {
      checkoutType: CHECKOUT_TYPE.NONE,
      newGmailPaidSeats: {
        quantity: 0,
        plan: 'PRO',
      },
      newWorkspaceSeats: {
        quantity: 0,
        plan: 'PRO',
      },
      manageUsers: () => {
        push(`${urlPrefix}/space/users`);
      },
      isGmail: isGmailUser,
      quantityChange: 0,
    };

    if (queryParams.get(CHECKOUT) === 'success') {
      const from = queryParams.get(FROM);
      const to = queryParams.get(TO);

      if (queryParams.get(NEW_GMAIL_SEATS) !== null) {
        result.newGmailPaidSeats.quantity = getNumber(queryParams.get(NEW_GMAIL_SEATS));
      }
      if (queryParams.get(NEW_WORKSPACE_SEATS) !== null) {
        result.newWorkspaceSeats.quantity = getNumber(queryParams.get(NEW_WORKSPACE_SEATS));
      }
      if (from === 'FREE' && to != null) {
        result.checkoutType = CHECKOUT_TYPE.NEW_PLAN_PURCHASE;
        return result;
      }
      if (queryParams.get(SEATS_ADDED) !== null) {
        result.checkoutType = CHECKOUT_TYPE.WORKSPACE_SEATS_ADDED;
        result.quantityChange = getNumber(queryParams.get(SEATS_ADDED));
        return result;
      }
      if (queryParams.get(SEATS_REMOVED) !== null) {
        result.checkoutType = CHECKOUT_TYPE.WORKSPACE_SEATS_REMOVED;
        result.quantityChange = getNumber(queryParams.get(SEATS_REMOVED));
        return result;
      }
    }

    return result;
  }, [search, urlPrefix, isGmailUser]);
};
