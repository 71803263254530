import {useMemo} from 'react';
import {PaidUserPlan} from "seats/data/requests/workspaceBilling";
import {useWorkspaceContext} from 'data/context/WorkspaceContext';
import {
  getComputeParamsForExistingSubscription, getDefaultComputeParams
} from 'seats/containers/Checkout/BillingDetailsSidebar/utils';
import {useSeatCheckoutContext} from "seats/data/context/CheckoutContext";
import {
  useComputeStripePaymentInfoV2,
  useWorkspaceActiveSubscriptionV2
} from "seats/data/hooks/useSeatsWorkspaceBilling";
import {useMe} from "data/hooks/useMe";
import {getAvailablePlanForEmail} from "seats/utils/workspaceBillingUtil";

export const useBillingDetailsComputePaymentInfo = () => {

  const {id: workspaceId, isLoading: isLoadingWorkspaceContext, user} = useWorkspaceContext();

  const { billingInterval, seatQuantity} = useSeatCheckoutContext();
  const {
    data: activeSubscription,
    isLoading: isLoadingActiveSubscription,
  } = useWorkspaceActiveSubscriptionV2({workspaceId, userRole: user?.role});

  const userData = useMe();

  const paidUserPlan: PaidUserPlan | undefined =  useMemo(() => {
    if (userData?.data?.email && !userData.isLoading) {
      return getAvailablePlanForEmail(userData.data.email);
    }
    return undefined;
  }, [userData?.data?.email, userData?.isLoading]);

  const computeStripePaymentInfoRequestPayload = useMemo(() => {
    if (!paidUserPlan) {
      return undefined;
    }
    if (activeSubscription) {
      return getComputeParamsForExistingSubscription(
        activeSubscription,
        seatQuantity,
      );
    }
    return getDefaultComputeParams(billingInterval, seatQuantity, paidUserPlan,);
  }, [activeSubscription, billingInterval, seatQuantity, paidUserPlan,]);

  // fetch payment info if not loading
  const {
    data: paymentInfo,
    isFetching: isFetchingPaymentInfo,
  } = useComputeStripePaymentInfoV2(
    isLoadingActiveSubscription ? undefined : workspaceId,
    computeStripePaymentInfoRequestPayload,
  );

  return {
    isLoading: isLoadingWorkspaceContext || isLoadingActiveSubscription,
    isFetchingPaymentInfo,
    paymentInfo,
  };
};
