import {Button, Dialog, FlexContainer, Text,} from '@scriptaddicts/yamm-ui-components';
import React, {FC} from 'react';
import {useConnect} from 'seats/containers/Main/Workspaces/GeneralSettings/TransferValidation/connect';
import {
  ActionContainer,
  Container,
  Title
} from 'seats/containers/Main/Workspaces/GeneralSettings/TransferValidation/styles';
import {Props} from 'seats/containers/Main/Workspaces/GeneralSettings/TransferValidation/types';

export const TransferValidation: FC<Props> = ({
  show,
  onClose,
  onCancel,
  value,
}) => {
  const {workspaceName, memberEmail, isLoading, onTransfer} = useConnect({
    show,
    onClose,
    onCancel,
    value,
  });
  return (
    <Dialog
      show={show}
      onClose={() => {
        if (!isLoading) {
          return;
        }
        onCancel();
        onClose();
      }}
    >
      <Container>
        <FlexContainer flex="vertical" gap="medium">
          <Title>
            {`Transfer ${workspaceName} ownership to ${memberEmail}?`}
          </Title>
          <Text
            type="paragraph"
            size="base"
            width="regular"
            style={{whiteSpace: 'pre-wrap', textAlign: 'center'}}
          >
            You cannot undo this action.{'\n'}
          </Text>
        </FlexContainer>
        <ActionContainer>
          <Button
            aria-label="transfer ownership"
            size="base"
            variant="primary"
            onClick={onTransfer}
            disabled={isLoading}
            loading={isLoading}
          >
            Yes, proceed
          </Button>
          <Button
            aria-label="cancel"
            size="base"
            variant="secondary"
            disabled={isLoading}
            onClick={() => {
              onCancel();
              onClose();
            }}
          >
            No, cancel
          </Button>
        </ActionContainer>
      </Container>
    </Dialog>
  );
};
