import styled from "styled-components";
import {TableRow} from "@scriptaddicts/yamm-ui-components";
import {CancelDowngradeButton} from "seats/components/SeatStyledComponents/styles";

export const FooterRow = styled(TableRow).attrs({value: "table-footer"})`

    background: ${({theme}) => theme.colors.gray100};
    box-shadow: 0 -1px 0 0 #E5E3E0;

    label, input {
        display: none;
    }
`;

export const FooterCancelDowngradeButton = styled(CancelDowngradeButton)`
    color: ${(props) => props.theme.colors.orange700};
    border-bottom: 0px;
`;