import styled from 'styled-components';
import { from } from 'styles/media';
import {Button as DefaultButton} from '@scriptaddicts/yamm-ui-components';

export const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 24px;
  padding-top: 28px;
  padding-bottom: 28px;

  ${from.tablet} {
    padding: 36px;
    width: 500px;
  }
`;

export const Content = styled.div`
  padding: 1.5rem;
  padding-right: calc(1.5rem - 0.375rem);
  margin-right: 0.375rem;
  flex: 1;
`;

export const AddSeatsButton = styled(DefaultButton)`
  display: flex;
  height: 40px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const AddSeatsButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

export const ButtonText = styled.div`
  color: ${(props) => props.theme.colors.white};
  font-variant-numeric: lining-nums tabular-nums;
  font-family: ${(props) => props.theme.fonts.inter};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 125%; /* 17.5px */
  letter-spacing: -0.21px;
`;
