import {useWorkspaceContext} from 'data/context/WorkspaceContext';
import {useTransferWorkspace} from 'data/hooks/useWorkspaceMembers';
import {transformError} from 'data/transform/error';
import {useCallback, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Props} from 'seats/containers/Main/Workspaces/GeneralSettings/TransferValidation/types';

export const useConnect = ({onClose, value}: Props) => {
  const {
    id,
    urlPrefix,
    details,
    setAlerts,
    setKeepPreviousWorkspace,
  } = useWorkspaceContext();
  const {replace} = useHistory();
  const {mutate: transferWorkspace, error, status} = useTransferWorkspace();

  const onTransfer = useCallback(
    () =>
      transferWorkspace({
        workspaceId: id,
        params: {userId: value?.id},
      }),
    [id, value],
  );

  useEffect(() => {
    if (status === 'success') {
      onClose();
      replace(`${urlPrefix}/mail-merges/sent`);
      setAlerts({
        transfer: {
          name: details?.name,
          email: value?.email,
        },
      });
    }
    if (status === 'error') {
      const backendError = transformError(error);
      if (
        backendError?.code === 409 &&
        backendError.errors.length > 0 &&
        backendError.errors[0].reason === 'user_owns_too_many_workspaces'
      ) {
        onClose('TOO_MANY_WORKSPACES');
      } else {
        setAlerts({error: {type: 'transfer'}});
      }
    }
    setKeepPreviousWorkspace(status);
  }, [
    onClose,
    replace,
    setAlerts,
    setKeepPreviousWorkspace,
    status,
    urlPrefix,
  ]);

  return {
    workspaceName: details?.name,
    memberEmail: value?.email,
    isLoading: status === 'loading',
    onTransfer,
  };
};
