import {useEffect, useMemo, useState} from 'react';
import {captureException} from '@sentry/react';
import {useHistory, useLocation} from 'react-router-dom';
import {
  logError,
} from 'utils/userJourneyLogging';
import {useSeatCheckoutContext} from 'seats/data/context/CheckoutContext';
import {useWorkspaceContext} from 'data/context/WorkspaceContext';
import {getErrorMessage,} from 'seats/containers/Checkout/SeatsBillingInformation/utils';
import useWorkspaceLocation from 'utils/router/useWorkspaceLocation';
import {
  getComputeParamsForExistingSubscription,
} from "seats/containers/Checkout/BillingDetailsSidebar/utils";
import {
  useAddSeats,
  useComputeStripePaymentInfoV2,
  useWorkspaceActiveSubscriptionV2
} from "seats/data/hooks/useSeatsWorkspaceBilling";
import {format} from "date-fns";
import {formatPriceSignOnly} from "utils/priceFormat";
import {AddSeatsRequest, PaidUserPlan} from "seats/data/requests/workspaceBilling";
import {MAX_USERS_PER_SPACE} from "data/constants";
import {SEATS_ADDED} from "seats/data/queryParams";
import {useRestrictAddRemoveSeatsPage} from "seats/data/hooks/useRestrictAddRemoveSeatsPage";
import {getSubscriptionPriceWithTax} from "../common/utils";

const getDifferenceInDays = (millis1:number, millis2:number) => {
  const diffInMs = Math.abs(millis1 - millis2);
  return Math.floor(diffInMs / (1000 * 60 * 60 * 24));
};

export const useConnect = () => {
  const {
    plan, seatQuantity, setSeatQuantity, isLoading: isLoadingCheckoutContext, isSeatQuantityReady, setIsSeatQuantityReady
  } = useSeatCheckoutContext();
  const {goBack, push} = useHistory();
  const {pathname} = useWorkspaceLocation();
  const {search} = useLocation();
  const {
    id: workspaceId, details: workspaceDetails, isLoading: isLoadingWorkspace, urlPrefix, user
  } = useWorkspaceContext();

  const {
    data: activeSubscription, isLoading: isLoadingActiveSubscription, refetch
  } = useWorkspaceActiveSubscriptionV2({workspaceId, userRole: user?.role});

  const {isRestrictAddRemoveSeatsPageInProgress} = useRestrictAddRemoveSeatsPage(workspaceId, urlPrefix, user?.role);

  const [submitError, setSubmitError] = useState<string | undefined>();

  const computeStripePaymentInfoRequestPayload = useMemo(() => {
    if (!activeSubscription) {
      return undefined;
    }
    return getComputeParamsForExistingSubscription(activeSubscription, seatQuantity);
  }, [activeSubscription, seatQuantity]);

  const {
    data: paymentInfo, isFetching: isFetchingPaymentInfo,
  } = useComputeStripePaymentInfoV2(
    isLoadingWorkspace ? undefined : workspaceId,
    computeStripePaymentInfoRequestPayload,
    activeSubscription?.subscriptionPaymentPlans[0].totalSeats
  );

  const {
    mutateAsync, isLoading: isSubmitting,
  } = useAddSeats();

  const perSeatAmount = useMemo(() => {
    return activeSubscription?.subscriptionPaymentPlans[0]?.tiers[0]?.unitAmount ?? 0;
  }, [activeSubscription]);

  const monthlyRate = useMemo(() => {
    return perSeatAmount / 12;
  }, [perSeatAmount]);

  const userPlanType = useMemo(() => {
    return activeSubscription?.subscriptionPaymentPlans[0]?.userPlanType as PaidUserPlan
  }, [activeSubscription])

  const subscriptionId = useMemo(() => {
    return activeSubscription?.subscriptionId
  }, [activeSubscription])

  const addClick = async () => {
    setSubmitError(undefined);
    if (workspaceId && subscriptionId && userPlanType) {
      try {
        const addSeatRequest: AddSeatsRequest = {
          useProratedAmounts: true,
          seatPlans: [
            {
              userPlan: userPlanType,
              quantity: seatQuantity
            }
          ]
        }
        const data = await mutateAsync({workspaceId, subscriptionId, ...addSeatRequest});
        if (data) {
          refetch();
          push(`${urlPrefix}/space/billing?checkout=success&${SEATS_ADDED}=${seatQuantity}`);
        }
      } catch (e) {
        window.scrollTo(0, 0);
        captureException(e);
        const {isField, key} = getErrorMessage(e);
        if (!isField) {
          setSubmitError(key);
        }
        logError({
          errorType: key, pagePath: pathname, search, workspaceId, selectedPlan: plan,
        });
      }
    } else {
      window.scrollTo(0, 0);
      captureException(new Error('Workspace ID of stripe plans are not found'));
      setSubmitError('Workspace ID of stripe plans are not found');
    }
  };

  const currentTotalSeats = activeSubscription?.subscriptionPaymentPlans[0]?.totalSeats || 0
  const maxSeatsLeftToAdd = useMemo(() => MAX_USERS_PER_SPACE - currentTotalSeats, [currentTotalSeats]);
  const totalSeatsAfterAddition = currentTotalSeats + seatQuantity;
  const currency = activeSubscription?.currency ?? 'USD';

  useEffect(() => {
    if(seatQuantity > maxSeatsLeftToAdd) {
      setSeatQuantity(maxSeatsLeftToAdd)
    }
  },[maxSeatsLeftToAdd])

  const formattedMonthlyRate = formatPriceSignOnly(monthlyRate, currency, 2)
  const proratedAmount = formatPriceSignOnly(paymentInfo?.chargedToday ?? 0, currency, 2);
  const nextPaymentAmount = formatPriceSignOnly(paymentInfo ? getSubscriptionPriceWithTax(paymentInfo) : 0, currency, 2);
  const nextPaymentDueDate = format(new Date(activeSubscription?.endTimestamp ?? 0), "dd MMMM yyyy");


  const proratedDays = useMemo(() => {
    if (!activeSubscription || !paymentInfo || seatQuantity === 0) {
      return 0;
    }
    const differenceInDays = getDifferenceInDays(
      activeSubscription.endTimestamp,
      activeSubscription.startTimestamp
    );
    const proratedPerSeatAmount = paymentInfo.chargedToday / seatQuantity;
    const taxIncludedPerSeatAmount = perSeatAmount * (1 + (activeSubscription.taxRate ?? 0) / 100);
    return Math.floor(differenceInDays * proratedPerSeatAmount / taxIncludedPerSeatAmount);
  }, [activeSubscription, paymentInfo, seatQuantity, perSeatAmount]);

  return {
    isLoading: isLoadingWorkspace || isLoadingActiveSubscription || isLoadingCheckoutContext || isRestrictAddRemoveSeatsPageInProgress,
    workspaceName: workspaceDetails?.name,
    isFetchingPaymentInfo,
    paymentInfo,
    seatQuantity,
    setSeatQuantity,
    addClick,
    cancelClick: () => {
      goBack();
    },
    maxSeatsLeftToAdd,
    currentTotalSeats,
    totalSeatsAfterAddition,
    formattedMonthlyRate,
    proratedAmount,
    nextPaymentAmount,
    nextPaymentDueDate,
    proratedDays,
    isSubmitting,
    submitError,
    userPlanType,
    isSeatQuantityReady,
    setIsSeatQuantityReady,
  };
};
