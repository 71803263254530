import React from 'react';
import { getPlanLabel } from 'utils/checkout';
import {
  isOfflineSubscription,
  isSupportGivenSubscription,
} from 'utils/subscriptions';
import FreePlanCard from "seats/containers/Main/Workspaces/Billing/FreePlanCard";
import Plan from './Plan';
import LegacyPlanExplanation from './LegacyPlanExplanation';
import { useConnect, useFuturePrice } from './connect';
import { LoadingSpinner } from './styles';
import CustomPlan from './CustomPlan';

export type CurrentPlanProps = ReturnType<typeof useConnect>  & {
  accountType: string;
};

const CurrentPlan = ({
  isLegacy,
  isPayPal,
  isLoading,
  manageSubscriptionData,
  urlPrefix,
  subscription,
  pageRestrictions,
  legacyPlanType,
  isGmailUser,
  accountType,
  subscriptionSeat
}: CurrentPlanProps) => {
  const { futurePrice } = useFuturePrice(subscription);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (subscription == null || subscription.workspacePlan === 'FREE') {
    return <FreePlanCard accountType={accountType}/>;
  }

  const isCustomPlan =
    isOfflineSubscription(subscription) ||
    isSupportGivenSubscription(subscription);

  return (
    <>
      {subscription.workspacePlan === 'LEGACY' && <LegacyPlanExplanation />}

      {isCustomPlan ? (
        <CustomPlan />
      ) : (
        <Plan
          workspaceUrlPrefix={urlPrefix}
          name={getPlanLabel(subscription.workspacePlan, legacyPlanType)}
          assignedSeats={subscription?.subscriptionPaymentPlans[0]?.assignedSeats || 0}
          totalSeats={subscription?.subscriptionPaymentPlans[0]?.totalSeats || 0}
          price={subscription.amount}
          newPrice={futurePrice}
          currency={subscription.currency}
          billingCycle={subscription.billingInterval}
          endDate={new Date(subscription.endTimestamp)}
          tax={subscription.taxRate ?? 0}
          isRenewal={subscription.autoRenew}
          isLegacy={isLegacy}
          isPayPal={isPayPal}
          manageSubscriptionLink={
            manageSubscriptionData?.stripeCustomerPortalUrl
          }
          pageRestrictions={pageRestrictions}
          isGmailUser={isGmailUser}
          subscriptionSeat={subscriptionSeat}
        />
      )}
    </>
  );
};

export default CurrentPlan;
