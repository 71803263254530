import {useCallback, useEffect} from "react";
import {useWorkspaceActiveSubscriptionV2} from "seats/data/hooks/useSeatsWorkspaceBilling";
import {useHistory} from "react-router-dom";
import {useMe} from "data/hooks/useMe";
import useSubscriptionSeat from "seats/data/hooks/useSubscriptionSeat";
import {WorkspaceUserRole} from "model/workspace";

export const useRestrictAddRemoveSeatsPage = (workspaceId?: string, urlPrefix?: string, userRole?: WorkspaceUserRole) => {

  const {replace} = useHistory();

  const {isGmailUser, isLoading: isLoadingMe} = useMe({keepPreviousData: true});

  const goToBilling = useCallback(() => {
    replace(`${urlPrefix}/space/billing`);
  }, [replace, urlPrefix]);

  const {data: activeSubscription, isLoading: isLoadingActiveSubscription} = useWorkspaceActiveSubscriptionV2({
      workspaceId,
      userRole
    });

  const {isLoadingSubscriptionSeat, subscriptionSeat} = useSubscriptionSeat(workspaceId, userRole);

  useEffect(() => {
      if (isLoadingMe) {
        return;
      }
      if (isGmailUser) {
        goToBilling();
      } else {
        const noActiveSubscription = !isLoadingActiveSubscription && activeSubscription === undefined;
        const hasScheduledRemoval = !isLoadingSubscriptionSeat && subscriptionSeat?.hasScheduledRemoval();
        if (noActiveSubscription || hasScheduledRemoval) {
          goToBilling();
        }
      }
    }, [isLoadingMe, isGmailUser, isLoadingActiveSubscription, activeSubscription, isLoadingSubscriptionSeat, subscriptionSeat, goToBilling]
  )

  return {
    isRestrictAddRemoveSeatsPageInProgress: isLoadingMe || isLoadingActiveSubscription || isLoadingSubscriptionSeat,
  };
};
