import {
  Button,
  FormControl,
  FormGroup,
  FormLabel,
  StyledOption,
  Selectbox,
} from '@scriptaddicts/yamm-ui-components';
import React, { FC } from 'react';
import { plurify } from 'utils/plurify';
import { useConnect } from './connect';
import { RemoveValidation } from './RemoveValidation';
import {
  FormContainer,
  PlainText,
  SettingContainer,
  TitleContainer,
  Divider,
  ActionContainer,
  LargeCloseButton,
  SmallCloseButton,
} from './styles';
import { Props } from './types';
import {
  AdminCannotBeRemovedByAdmin,
  AdminCannotBeRemovedByHimself,
  FreeTrialUsersCannotBeRemoved,
  OwnerCannotBeRemoved,
} from './alerts';

export const ManageUser: FC<Props> = (props) => {
  const {
    active,
    pending,
    isLoading,
    activeFormChanged,
    changeMembers,
    removeActiveMembers,
    removePendingMembers,
  } = useConnect(props);
  const { onClose } = props;

  return (
    <>
      <RemoveValidation {...removeActiveMembers} />

      {!!active.count && !!active.user ? (
        <LargeCloseButton
          onClick={onClose}
        />
      ) : (
        <SmallCloseButton
          onClick={onClose}
        />
      )}

      {!!active.count && (
        <>
          {active.user ? (
            <TitleContainer>
              <PlainText size="extraLarge" width="bold">
                {active.user.name}
              </PlainText>
              <PlainText size="base" width="regular">
                {active.user.email}
              </PlainText>
            </TitleContainer>
          ) : (
            <TitleContainer>
              <PlainText size="extraLarge" width="bold">
                {active.count} {plurify(active.count, 'user', 'users')}{' '}
                selected:
              </PlainText>
            </TitleContainer>
          )}
          <SettingContainer>
            {active.features.change.role && (
              <FormContainer>
                  <FormGroup
                    disabled={isLoading || active.disabled.change.role}
                  >
                    <FormLabel
                      htmlFor="member-role"
                      tooltip="Admins can manage users. Ownership transfer is available from the space settings."
                    >
                      Role
                    </FormLabel>
                    <FormControl>
                      <Selectbox
                        id="member-role"
                        onChange={changeMembers.role.onChange}
                        value={changeMembers.role.value}
                        variant="gray"
                        maxElement="auto"
                        minElement={5}
                      >
                        <StyledOption value="MEMBER" label="User" />
                        <StyledOption value="ADMIN" label="Admin">
                          Can manage users
                        </StyledOption>
                      </Selectbox>
                    </FormControl>
                  </FormGroup><ActionContainer>
                      <Button
                        variant="primary"
                        aria-label="Save settings"
                        data-log-event="go_to_checkout_summary"
                        data-log-event-data={JSON.stringify({
                          displayName: 'Save',
                        })}
                        disabled={isLoading ||
                          changeMembers.isLoading ||
                          !Object.entries(active.features.change).some(
                            ([key, value]) => value &&
                              !(active.disabled.change as Record<string, boolean>)[key] &&
                              activeFormChanged[key]
                          )}
                        loading={changeMembers.isLoading}
                        onClick={changeMembers.onSave}
                      >
                        Save
                      </Button>
                      <Button
                        variant="secondary"
                        aria-label="Cancel settings"
                        disabled={isLoading || changeMembers.isLoading}
                        onClick={changeMembers.onCancel}
                      >
                        Cancel
                      </Button>
                    </ActionContainer>
              </FormContainer>
            )}
            {active.features.change.role &&
              active.features.remove && <Divider />}
            {active.features.remove && (
              <>
                <Button
                  variant="danger"
                  aria-label="Remove from space"
                  disabled={isLoading || active.disabled.remove}
                  onClick={removeActiveMembers.onOpen}
                >
                  Remove from space
                </Button>
                {active.warnings.cannotRemoveOwner && (
                  <OwnerCannotBeRemoved />
                )}
                {active.warnings.cannotRemoveAdminUsers && (
                    <AdminCannotBeRemovedByAdmin />
                )}
                 {active.warnings.cannotRemoveAdminMyself && (
                    <AdminCannotBeRemovedByHimself />
                )}
                {active.warnings.cannotRemoveFreeTrialUsers && (
                    <FreeTrialUsersCannotBeRemoved />
                )}
              </>
            )}
          </SettingContainer>
        </>
      )}
      {!!pending.count && (
          <>
            <TitleContainer>
              <PlainText type="span" size="extraLarge" width="bold">
                {pending.count} pending{' '}
                {plurify(pending.count, 'user', 'users')} selected:
              </PlainText>
            </TitleContainer>
            <SettingContainer>
              {pending.features.remove && (
                  <Button
                      variant="danger"
                      aria-label="Remove from space"
                      disabled={isLoading}
                      onClick={removePendingMembers.onOpen}
                  >
                    Remove from space
                  </Button>
              )}
              <RemoveValidation {...removePendingMembers} />
            </SettingContainer>
          </>
      )}
    </>
  );
};
