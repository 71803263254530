import {useMutation} from "react-query";
import {AxiosError} from "axios";
import {BackendError} from "model/error";
import {queryClient} from "data/queryClient";
import {patchUnassignUsers} from "../requests/workspaceManageUsers";
import {UnassignUsersRequest} from "../requests/workspaceBilling";

export const useUnassignUsersToSeats = () => {
    return useMutation<
        any,
        AxiosError<BackendError>,
        {
            workspaceId?: string;
            params?: UnassignUsersRequest;
        }
    >(
        async (params) => {
            if (!params.workspaceId || !params.params) return Promise.resolve([]);
            return patchUnassignUsers(params);
        },
        {
            onSuccess: async (_result, params) =>
                Promise.all([
                    queryClient.invalidateQueries([
                        'space',
                        params.workspaceId,
                        'subscriptions',
                    ]),
                ]),
        },
    );
};
