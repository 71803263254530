import {useWorkspaceContext} from 'data/context/WorkspaceContext';
import {useUpdateWorkspace} from 'data/hooks/useWorkspace';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {useWorkspaceMembersWithSeat} from "seats/data/hooks/useWorkspaceMembersWithSeat";
import {FETCH_WORKSPACE_USERS_MAX} from "data/constants";
import {WorkspaceMemberWithSeat} from "seats/model/WorkspaceMemberWithSeats";

export const useConnect = () => {
  const {id, user, details, restrictions, urlPrefix} = useWorkspaceContext();

  const {isLoading, data: members} = useWorkspaceMembersWithSeat(id, {
    filters: {
      userStatus: ['ACTIVE'],
    },
    limit: FETCH_WORKSPACE_USERS_MAX,
  });

  const [fieldValidation, setFieldValidation] = useState<{
    workspaceName?: 'REQUIRED' | 'EMPTY' | 'TOO_SHORT' | 'TOO_LONG';
    workspaceOwner?: 'NOT_SELECTED' | 'TOO_MANY_WORKSPACES';
  }>({
    workspaceName: undefined,
    workspaceOwner: undefined,
  });

  // General Updates
  const [workspaceName, setWorkspaceName] = useState<string | undefined>(
    details?.name,
  );

  const {
    mutate: updateWorkspace,
    status: updateWorkspaceStatus,
    reset: updateWorkspaceReset,
  } = useUpdateWorkspace();
  const onWorkspaceGeneralUpdate = useCallback(() => {
    updateWorkspaceReset();
    if (workspaceName === details?.name) {
      return;
    }
    if (!workspaceName) {
      setFieldValidation((props) => ({...props, workspaceName: 'REQUIRED'}));
      return;
    }
    if (workspaceName && !workspaceName.trim()) {
      setFieldValidation((props) => ({...props, workspaceName: 'EMPTY'}));
      return;
    }
    if (workspaceName.trim().length < 5) {
      setFieldValidation((props) => ({...props, workspaceName: 'TOO_SHORT'}));
      return;
    }
    if (workspaceName.trim().length > 255) {
      setFieldValidation((props) => ({...props, workspaceName: 'TOO_LONG'}));
      return;
    }
    setFieldValidation((props) => ({...props, workspaceName: undefined}));
    updateWorkspace({
      workspaceId: id,
      params: {workspaceName: workspaceName.trim()},
    });
  }, [setFieldValidation, updateWorkspaceReset, workspaceName, details?.name]);

  const onWorkspaceGeneralCancel = useCallback(() => {
    updateWorkspaceReset();
    setFieldValidation((props) => ({...props, workspaceName: undefined}));
    setWorkspaceName(details?.name);
  }, [
    setFieldValidation,
    updateWorkspaceReset,
    setWorkspaceName,
    details?.name,
  ]);
  useEffect(() => {
    if (workspaceName !== details?.name) {
      onWorkspaceGeneralCancel();
    }
  }, [details?.name]);

  const general = useMemo(() => {
    return {
      name: {
        value: workspaceName,
        onChange: setWorkspaceName,
        error: fieldValidation.workspaceName,
      },
      onCancel: onWorkspaceGeneralCancel,
      onSave: onWorkspaceGeneralUpdate,
      isLoading: updateWorkspaceStatus === 'loading',
      isSuccess: updateWorkspaceStatus === 'success',
      disabled: details?.name === workspaceName,
    };
  }, [
    workspaceName,
    setWorkspaceName,
    fieldValidation,
    onWorkspaceGeneralUpdate,
    updateWorkspaceStatus,
    details?.name,
  ]);

  // Owner Update
  const [workspaceOwner, setWorkspaceOwner] = useState<
    WorkspaceMemberWithSeat | undefined
  >(members.find((member) => member.role === 'OWNER'));

  const [
    isTransferValidationShown,
    setIsTransferValidationShown,
  ] = useState<boolean>(false);
  const onTransfer = useCallback(() => {
    if (!workspaceOwner) {
      setFieldValidation((props) => ({
        ...props,
        workspaceOwner: 'NOT_SELECTED',
      }));
      return;
    }
    if (workspaceOwner?.role === 'OWNER') {
      return;
    }
    setIsTransferValidationShown(true);
  }, [workspaceOwner, setFieldValidation, setIsTransferValidationShown]);

  const onTransferClose = useCallback(
    (error?: 'TOO_MANY_WORKSPACES') => {
      if (error) {
        setFieldValidation((props) => ({
          ...props,
          workspaceOwner: error,
        }));
      }
      setIsTransferValidationShown(false);
    },
    [setFieldValidation, setIsTransferValidationShown],
  );

  const onTransferCancel = useCallback(() => {
    setWorkspaceOwner(members.find((member) => member.role === 'OWNER'));
  }, [setWorkspaceOwner, members]);
  useEffect(() => {
    onTransferCancel();
  }, [members]);

  const owner = useMemo(() => {
    const ownerCandidates = members.filter((member) => member.seatType === 'ADDED_YAMM_USER' && member.status !== 'FREE_TRIAL');
    return {
      isRestricted: !!restrictions?.TRANSFER_WORKSPACE,
      warning: (() => {
        if (user?.features.transferOwnerShip) {
          return undefined;
        }
        if (details?.plan === 'FREE') {
          return 'FREE';
        }
        if (details?.plan === 'LEGACY') {
          return 'LEGACY';
        }
        return undefined;
      })(),
      options: ownerCandidates,
      value: workspaceOwner,
      onChange: setWorkspaceOwner,
      show: isTransferValidationShown,
      onTransfer,
      onCancel: onTransferCancel,
      onClose: onTransferClose,
      error: fieldValidation.workspaceOwner,
      disabled: workspaceOwner?.role === 'OWNER',
      isDetailedLoaded: details !== undefined && !isLoading,
    };
  }, [
    user,
    details,
    members,
    workspaceOwner,
    setWorkspaceOwner,
    isTransferValidationShown,
    onTransfer,
    onTransferClose,
    onTransferCancel,
    fieldValidation,
    isLoading
  ]);

  return {
    name: details?.name,
    general,
    owner,
    urlPrefix
  };
};
