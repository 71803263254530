import {
  Dialog,
  FlexContainer,
  Heading,
  Color,
  Alert,
  Text,
  Tooltip,
} from '@scriptaddicts/yamm-ui-components';
import React, {FC} from 'react';
import {plurify} from 'utils/plurify';
import {
  LinksSection,
  Loading,
  Of, Progress, Progressbar,
  SeatsStatusContainer,
  SeatsStatusTextContainer, SeatsUsed, SeatsUsedContainer, StyledListItem
} from "seats/containers/Main/Workspaces/Billing/CurrentPlan/styles";
import {ManageSeatsProps} from 'seats/widgets/ManageSeats/types';
import {ReactComponent as PlusIcon} from 'assets/images/icons/plus-add-seats.svg';
import {formatLongMonthDayYear} from "utils/dates";
import {useConnect} from 'seats/widgets/ManageSeats/connect';
import {AddSeatsButton, AddSeatsButtonContainer, ButtonText, Container} from 'seats/widgets/ManageSeats/styles';
import {CancelDowngradeButton, MangeSeatsSupportLink, Stronger, StyledSupportLink} from "seats/components/SeatStyledComponents/styles";

const SupportLinkAttrs = {
  href: 'https://support.yet-another-mail-merge.com/hc/en-us/requests/new',
  rel: 'noreferrer',
  target: '_blank',
};

export const ManageSeats: FC<ManageSeatsProps> = ({
  show,
  onClose,
}) => {
  const {
    isLoading,
    isMobile,
    subscriptionSeat,
    onAddSeats,
    onRemoveUnusedSeats,
    canManageSeats,
    isSeatsLimitReached,
    isOfflineOrSupportGivenSubscription,
    onCancelScheduledDowngrade
  } = useConnect( );

  const hasAvailableSeats = subscriptionSeat.hasAvailableSeats();
  const hasScheduledRemoval = subscriptionSeat.hasScheduledRemoval();

  const shouldDisplayContactOwnerAlert =  !canManageSeats && !hasAvailableSeats;
  const shouldDisplayAddRemoveSeats =  canManageSeats && hasAvailableSeats && !hasScheduledRemoval;
  const shouldDisplayCancelScheduledRemovalAlert =  canManageSeats && hasScheduledRemoval;
  const shouldDisplayAddSeatsButton = canManageSeats && !hasAvailableSeats && !hasScheduledRemoval && !isSeatsLimitReached && !isOfflineOrSupportGivenSubscription;

  return (
    <Dialog
      show={show}
      onClose={() => {
        if (isLoading) {
          return;
        }
        onClose();
      }}
      close={isMobile ? 'overlay' : 'both'}
    >
      <Container>
        <FlexContainer flex="horizontal">
          <Heading size="large">Manage seats</Heading>
        </FlexContainer>
        <FlexContainer flex="vertical" gap="base">
          <SeatsStatusContainer>
            <SeatsStatusTextContainer>
              <SeatsUsedContainer>
                <Of>{`${subscriptionSeat.assignedSeats} of ${subscriptionSeat.totalSeats} `}</Of>
                <SeatsUsed>{plurify(subscriptionSeat.totalSeats, 'seat', 'seats')} used</SeatsUsed>
              </SeatsUsedContainer>
            </SeatsStatusTextContainer>
            <Loading>
              <Progressbar>
                <Progress style={{width: `${subscriptionSeat.getUsagePercentage()}%`}}/>
              </Progressbar>
            </Loading>
          </SeatsStatusContainer>
          {shouldDisplayAddRemoveSeats && (
              <LinksSection>
                <StyledListItem onClick={onAddSeats}>
                  <Tooltip
                      maxWidth="auto"
                      placement="top"
                      key="add-seats"
                      allowHTML
                      interactive
                      tip={isSeatsLimitReached && (
                          <Text size="small">If you want to have more than 100 seats, please <StyledSupportLink {...SupportLinkAttrs}>Contact
                            Support</StyledSupportLink></Text>)}
                      disabled={!isSeatsLimitReached}
                  >
                    <Color variant="info" style={{opacity: isSeatsLimitReached ? 0.5 : 1}}>Add seats</Color>
                  </Tooltip>
                </StyledListItem>
                <StyledListItem onClick={onRemoveUnusedSeats}>
                  <Color variant="info">Remove unused seats</Color>
                </StyledListItem>
              </LinksSection>)
          }
          {
              shouldDisplayCancelScheduledRemovalAlert && (
                  <Alert type="warning" style={{marginTop: '16px'}}>
                    <Text type="paragraph" size="large" width="regular">
                      If you wish to add or remove more seats, you first need to cancel
                      the <Stronger>scheduled
                      downgrade
                      to {subscriptionSeat.scheduledRemoval?.targetSeatsQuantity} {plurify(
                        subscriptionSeat.scheduledRemoval?.targetSeatsQuantity,
                        'seat',
                        'seats'
                      )}</Stronger> that
                      will take effect
                      on {formatLongMonthDayYear(subscriptionSeat.scheduledRemoval?.removeSeatsAtTimestamp || 0)}.
                      <br/>Do you want to cancel
                      the downgrade?<br/><CancelDowngradeButton onClick={onCancelScheduledDowngrade}>Cancel
                      downgrade</CancelDowngradeButton>
                    </Text>
                  </Alert>
              )
          }
          {
            isOfflineOrSupportGivenSubscription && (
              <Alert type="warning" style={{marginTop: '16px'}}>
                <Text size="large">If you wish to add or remove more seats, please<br/><MangeSeatsSupportLink {...SupportLinkAttrs}>contact
                  support</MangeSeatsSupportLink></Text>
              </Alert>
            )
          }
        </FlexContainer>
        {shouldDisplayAddSeatsButton && (
            <FlexContainer flex="vertical" gap="base" style={{alignItems: 'flex-end'}}>
              <AddSeatsButton
                  aria-label="Add seats"
                  variant="primary"
                  className="autoTagCatch"
                  onClick={onAddSeats}
              >
                <AddSeatsButtonContainer>
                  <PlusIcon width="16px" height="16px"/>
                  <ButtonText>Add seats</ButtonText>
                </AddSeatsButtonContainer>
              </AddSeatsButton>
            </FlexContainer>
        )}
        {shouldDisplayContactOwnerAlert && (
            <Alert type="warning">
              <Text>If you want to add more users, please contact the space owner to purchase additional seats.</Text>
            </Alert>
        )}
      </Container>
    </Dialog>
  );
};
