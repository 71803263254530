import styled from 'styled-components';
import {Link, LinkButton, Strong} from '@scriptaddicts/yamm-ui-components';

export const StyledSupportLink = styled(Link)`
  color: #5BABF5;
`;

export const MangeSeatsSupportLink = styled(Link)`
    color: ${(props) => props.theme.colors.orange700};
    text-decoration-line: underline;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 165%;
    letter-spacing: -0.225px;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
`;

export const CancelDowngradeButton = styled(LinkButton)`
    color: ${(props) => props.theme.colors.orange700};

    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 165%;
    letter-spacing: -0.225px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: auto;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
`;

export const Stronger = styled(Strong)`
    font-weight: 700;
`;