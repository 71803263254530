import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useCallback, useEffect, useMemo } from 'react';
import { useUnassignUsersToSeats } from 'seats/data/hooks/useUnassignUsersToSeats';
import { Props } from './types';
import {queryClient} from "../../../../../../../data/queryClient";
import {optimisticUpdateWorkspaceMemberCount} from "../../../../../../../data/updates/workspace";

export const useConnect = ({
  onClose,
  members,
  setUserAlerts,
  onCloseDrawer,
}: Props) => {
  const { id, details } = useWorkspaceContext();
  const { mutate: removeMember, status, reset } = useUnassignUsersToSeats();

  const memberEmails = useMemo<string[]>(
      () => (members ?? []).map(({ email }) => email),
      [members],
  );

  const onRemove = useCallback(async () => {
    removeMember({
      workspaceId: id,
      params: {
        userEmails: memberEmails,
      },
    });
  }, [id, members]);

  const memberNames = useMemo<string[]>(
    () => (members ?? []).map(({ name }) => name),
    [members],
  );

  const hasPaidUser = useMemo<boolean>(
    () => !!members?.some(({ plan }) => plan !== 'FREE'),
    [members],
  );

  const hasAdminUser = useMemo<boolean>(
      () => !!members?.some(({ role }) => role !== 'ADMIN'),
      [members],
  );

  useEffect(() => {
    if (status === 'success') {
      onClose();
      setUserAlerts({
        removedMembers: memberNames,
      });
      Promise.all([
        queryClient.invalidateQueries([
          'space',
          id,
          'members',
        ]),
        optimisticUpdateWorkspaceMemberCount(
            id,
            -(memberEmails.length ?? 0),
        ),
      ]);
      reset();
      onCloseDrawer();
    }
  }, [setUserAlerts, reset, onClose, onCloseDrawer, status, memberNames]);

  return {
    workspaceName: details?.name,
    isLoading: status === 'loading',
    onRemove,
    memberNames,
    hasPaidUser,
    hasAdminUser
  };
};
