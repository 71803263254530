import { captureException } from "@sentry/react";
import { SEAT_CHECKOUT_SESSION_QUANTITY } from 'seats/data/constants';
import { useSeatCheckoutContext } from "seats/data/context/CheckoutContext";
import { useWorkspaceContext } from "data/context/WorkspaceContext";
import { PLAN, INTERVAL } from "data/queryParams";
import {useEffect, useState} from "react";
import { useHistory } from "react-router-dom";
import useWorkspaceLocation from "utils/router/useWorkspaceLocation";
import {useMe} from "data/hooks/useMe";
import {getNextLink} from "seats/containers/Checkout/BillingOptions/utils";
import {PaidUserPlan} from "seats/data/requests/workspaceBilling";

function prepareQueryStringForInformationPage(plan: PaidUserPlan , billingInterval: "YEARLY" | "MONTHLY") {
  const urlParams = new URLSearchParams('');
  urlParams.set(PLAN, plan);
  urlParams.set(INTERVAL, billingInterval);
  return urlParams;
}

export const useConnect = () => {

  const {isLoading: isUserLoading, isGmailUser} = useMe();
  const { url } = useWorkspaceLocation();
  const {
    plan, billingInterval, isLoading: isLoadingCheckout, checkoutFlowType, seatQuantity, setSeatQuantity, setIsSeatQuantityReady,
  } = useSeatCheckoutContext();

  const {
    details: workspaceDetails, isLoading: isLoadingWorkspace,
  } = useWorkspaceContext();

  const { push, replace } = useHistory();
  const [error, setError] = useState<string | undefined>(undefined);

  const onSubmit = (replacePath?: boolean) => {
    setError(undefined);

    const urlParams = prepareQueryStringForInformationPage(plan as PaidUserPlan, billingInterval);
    const nextUrl = getNextLink(checkoutFlowType);

    if (nextUrl) {
      try {
        sessionStorage.setItem(
          SEAT_CHECKOUT_SESSION_QUANTITY,
          JSON.stringify(seatQuantity)
        );
      } catch (e) {
        // ignore
      }
      if (replacePath) {
        replace(`${url}${nextUrl}?${urlParams.toString()}`);
      } else {
        push(`${url}${nextUrl}?${urlParams.toString()}`);
      }
    } else {
      setError('server-error');
      captureException(
        new Error(
          'BillingOptions->onSubmit: checkoutFlowType not handled properly'
        )
      );
    }
  };

  const isLoading = isLoadingWorkspace || isLoadingCheckout || isUserLoading;

  const [accountTypeNotReady, setAccountTypeNotReady] = useState<boolean>(true)

  useEffect(() => {
    if (!isUserLoading) {
      if (isGmailUser) {
        onSubmit(true);
      } else {
        setAccountTypeNotReady(false);
      }
    }
  }, [isUserLoading, isGmailUser]);

  return {
    isLoading: isLoading || isUserLoading || accountTypeNotReady,
    submitError: error,
    workspaceDetails,
    checkoutFlowType,
    onSubmit,
    setSeatQuantity,
    seatQuantity,
    setIsSeatQuantityReady
  };
};
