import {
  Autocomplete,
  Button,
  Dialog,
  FlexContainer,
  FormControl,
  FormGroup,
  FormLabel,
  Text,
  DropdownList,
  DropdownListItem,
  Strong,
  Heading,
  TagInput,
} from '@scriptaddicts/yamm-ui-components';
import React, {FC} from 'react';
import {plurify} from 'utils/plurify';
import {nanoid} from "nanoid";
import {useConnect} from 'seats/widgets/AssignUserToSeat/connect';
import {Container, globalStyles} from 'seats/widgets/AssignUserToSeat/styles';
import {Props} from 'seats/widgets/AssignUserToSeat/types';
import {Stronger} from "seats/components/SeatStyledComponents/styles";

export const AssignUserToSeat: FC<Props> = ({
  show,
  onClose,
  focusRef,
  onSuccess,
}) => {
  const {
    isLoading,
    resetForm,
    domain,
    search,
    tags,
    assign,
    isMobile,
    availableSeats
  } = useConnect({
                   onClose,
                   onSuccess,
                 });

  const addUserButtonText = assign.count
    ? `Add ${assign.count} ${plurify(assign.count, 'user', 'users')}`
    : 'Add user';

  return (
    <><style>{globalStyles}</style>
    <Dialog
      show={show}
      onClose={() => {
        if (isLoading) {
          return;
        }
        onClose();
        resetForm();
      }}
      close={isMobile ? 'overlay' : 'both'}
    >
      <Container>
        <FlexContainer flex="horizontal">
          <Heading size="large">Add users</Heading>
        </FlexContainer>

        <Text type="paragraph" size="base" width="regular">
          There {plurify(availableSeats, 'is', 'are')}{' '}
          <Stronger>{availableSeats} available {plurify(
            availableSeats,
            'seat',
            'seats'
          )}</Stronger> in this space. You can only add <Strong>{`@${domain}`}</Strong> users.{' '}

        </Text>

        <FormGroup>
          <FormLabel htmlFor="workspace-mails">Email addresses</FormLabel>
          <FormControl
            errorMessage={
              tags.hasError.partial
            }
          >
            <Autocomplete
              show={search.show}
              value={search.value}
              onChange={search.onChange}
              onOpen={search.onOpen}
              onClose={search.onClose}
              disabled={isLoading}
            >

              <DropdownList maxElement="auto" minElement={5}>
                {search.items.map((member) => (
                  <DropdownListItem key={nanoid()} value={member.email}>
                    {member.name
                      ? `${member.name} <${member.email}>`
                      : member.email}
                  </DropdownListItem>
                ))}
              </DropdownList>

              <TagInput
                innerRef={focusRef}
                className="tag-input-emails"
                id="workspace-mails"
                placeholder="Separate emails with a space or a comma"
                tagKeys={[' ', ',']}
                tags={tags.userEmailTags}
                onTagAdd={tags.onTagAdd}
                onTagRemove={tags.onTagRemove}
                hasError={tags.hasError.all}
                variant="gray"
                submitClassName="autoTagCatch"
                onSubmit={() => {
                }}
                maxRow={5}
              />
            </Autocomplete>
          </FormControl>
        </FormGroup>
        <FlexContainer flex="horizontal" gap="base">
          <Button
            data-log-event="add_users"
            data-log-event-data={JSON.stringify({
                                                  displayName: addUserButtonText,
                                                })}
            aria-label="invite"
            variant="primary"
            className="autoTagCatch"
            onClick={assign.onClick}
            disabled={isLoading || assign.disabled}
            loading={isLoading}
          >
            {addUserButtonText}
          </Button>
          <Button
            aria-label="cancel"
            variant="secondary"
            disabled={isLoading}
            onClick={() => {
              onClose();
              resetForm();
            }}
          >
            Cancel
          </Button>
        </FlexContainer>
      </Container>
    </Dialog></>
  );
};
