import {WorkspaceMemberWithSeat, WorkspaceMemberWithSeatInput} from "seats/model/WorkspaceMemberWithSeats";

function getWorkspaceUserStatus(user: WorkspaceMemberWithSeatInput): WorkspaceMemberWithSeat['status'] {
  if (user.freeTrialEndTimestamp) {
    return 'FREE_TRIAL';
  }
  return user.seatType === 'PENDING_YAMM_USER' ? 'PENDING' : 'ACTIVE';
}

export const transformWorkspaceUsers = (
  users: WorkspaceMemberWithSeatInput[],
): WorkspaceMemberWithSeat[] => {
  return users.map(
    (user) =>
      ({
        workspaceId: user.workspaceId,
        email: user.userEmail,
        status: getWorkspaceUserStatus(user),
        seatType: user.seatType,
        id: user.userId,
        name: user.userName,
        role: user.userRole,
        plan: user.userPlan,
        createTimestamp: Number(user.createTimestamp),
        lastUpdateTimestamp: Number(user.lastUpdateTimestamp),
        lastBecomePaidTimestamp: Number(user.lastBecomePaidTimestamp),
        isPersonal: user.isPersonal,
      } as WorkspaceMemberWithSeat),
  );
};
