import { useRollout, useUserWorkspace } from 'data/hooks/useMe';
// eslint-disable-next-line import/no-cycle
import { useWorkspace } from 'data/hooks/useWorkspace';
import { UserCounters } from 'model/counters';
import {
  UserFeatures,
  WorkspaceFeatureInput,
  WorkspaceFeatures,
} from 'model/features';
import { Plan } from 'model/plan';
import React, {
  createContext,
  useContext,
  FC,
  Dispatch,
  useEffect,
  useState,
  useMemo,
  useReducer,
  DispatchWithoutAction,
} from 'react';
import { QueryStatus } from 'react-query';
import { RolloutRestrictions } from 'utils/rollout';
import { useAlerts, WorkspaceAlerts } from './useAlerts';
import {
  WorkspacePlan,
  WorkspaceLegacyPlanType,
  WorkspaceUserRole,
} from '../../../model/workspace';
import { useActiveWorkspaceId } from './useActiveWorkspaceId';
import { getRoleValue, RoleValue } from '../../../utils/roleValue';
import { useUserAlerts } from '../../../containers/Main/Workspaces/UserSettings/UserAlerts/useUserAlerts';
import { UserAlertsProps } from '../../../containers/Main/Workspaces/UserSettings/UserAlerts/types';
import {useBillingAlerts} from "../../../seats/containers/Main/Workspaces/Billing/BillingAlerts/useBillingAlerts";
import {BillingAlertsProps} from "../../../seats/containers/Main/Workspaces/Billing/BillingAlerts/types";

export type WorkspaceDetails = {
  name: string;
  plan: WorkspacePlan;
  legacyType?: WorkspaceLegacyPlanType;
  status: 'ACTIVE' | 'DISABLED' | 'REMOVED' | 'LEGACY';
  memberCount: number;
  features: WorkspaceFeatures;
  domains: string[];
};

export type WorkspaceUser = {
  plan: Plan;
  role: WorkspaceUserRole;
  status: 'ACTIVE' | 'DISABLED' | 'REMOVED' | 'LEGACY';
  counters: UserCounters;
  features: UserFeatures;
};

export type OtherWorkspace = {
  id: string;
  name: string;
  urlPrefix: string;
  status: 'ACTIVE' | 'DISABLED' | 'REMOVED' | 'LEGACY';
  role: 'MEMBER' | 'ADMIN' | 'OWNER';
  workspaceUserFeature: WorkspaceFeatureInput | undefined;
};

export class OtherWorkspaceDecorator {
  workspace: OtherWorkspace | undefined;

  workspaceUserFeature: WorkspaceFeatureInput | undefined;

  constructor(otherWorkspace: OtherWorkspace | undefined) {
    this.workspace = otherWorkspace;
    this.workspaceUserFeature = this.workspace?.workspaceUserFeature;
  }

  hasSpacePlan(): boolean {
    if (this.workspaceUserFeature?.workspacePlan === undefined) {
      return false;
    }
    return new Set<'LEGACY' | 'OFFLINE' | 'FREE' | 'PRO' | 'PREMIUM'>([
      'FREE',
      'PRO',
      'PREMIUM',
    ]).has(this.workspaceUserFeature?.workspacePlan);
  }

  hasLegacyPlan(): boolean {
    return this.workspaceUserFeature?.workspacePlan === 'LEGACY';
  }

  isAdminOrOwner(): boolean {
    return this.workspace?.role === 'OWNER' || this.workspace?.role === 'ADMIN';
  }

  canSendInvitation(): boolean {
    if (this.workspace === undefined) return false;
    return this.hasSpacePlan() && this.isAdminOrOwner();
  }

  hasAccessToUsers(): boolean {
    const isSpacePlan = this.hasSpacePlan();
    const isLegacyPlan = this.hasLegacyPlan();
    const isAdminOrOwner = this.isAdminOrOwner();
    return isSpacePlan || (isAdminOrOwner && isLegacyPlan);
  }

  hasAccessToGeneralSettings(): boolean {
    return this.hasAccessToWorkspace() && this.workspace?.role === 'OWNER';
  }

  hasAccessToBilling(): boolean {
    return this.hasAccessToWorkspace() && this.isAdminOrOwner();
  }

  hasAccessToWorkspace(): boolean {
    if (this.workspace === undefined) return false;
    return getRoleValue(this.workspace?.role) > RoleValue.MEMBER;
  }

  isFreeMemberInProSpace(): boolean {
    const workspacePlan = this.workspaceUserFeature?.workspacePlan;
    const userRole = this.workspace?.role;
    const userPlan = this.workspaceUserFeature?.userPlan;
    return (
      userRole === 'MEMBER' && workspacePlan === 'PRO' && userPlan === 'FREE'
    );
  }
}

export type WorkspaceRestrictions = RolloutRestrictions;

export type WorkspaceProps = {
  id?: string;
  urlPrefix: string;
  isLoading: boolean;
  details?: WorkspaceDetails;
  user?: WorkspaceUser;
  otherWorkspaces: OtherWorkspace[];
  alerts: WorkspaceAlerts;
  setAlerts: Dispatch<WorkspaceAlerts>;
  setAlertsSeen: DispatchWithoutAction;
  setKeepPreviousWorkspace: Dispatch<QueryStatus>;
  setSelectedWorkspace: Dispatch<string | undefined>;
  isPreviousWorkspace: boolean;
  restrictions?: WorkspaceRestrictions;
  userAlerts: UserAlertsProps;
  setUserAlerts: React.Dispatch<Partial<UserAlertsProps>>;
  billingAlerts: BillingAlertsProps;
  setBillingAlerts: React.Dispatch<Partial<BillingAlertsProps>>;
};
const DEFAULT_WORKSPACE: WorkspaceProps = {
  isLoading: false,
  urlPrefix: '',
  otherWorkspaces: [],
  alerts: {},
  setAlerts: () => {},
  setAlertsSeen: () => {},
  setKeepPreviousWorkspace: () => {},
  setSelectedWorkspace: () => {},
  isPreviousWorkspace: false,
  userAlerts: {},
  setUserAlerts: () => {},
  billingAlerts: {},
  setBillingAlerts: () => {},
};

const WorkspaceContext = createContext<WorkspaceProps>(DEFAULT_WORKSPACE);

export const useWorkspaceContext = () => useContext(WorkspaceContext);

export const WorkspaceContextProvider: FC = ({ children }) => {
  const { selectedWorkspace, setSelectedWorkspace } = useActiveWorkspaceId();

  const [keepPreviousWorkspace, setKeepPreviousWorkspace] = useReducer(
    (state: boolean, action: QueryStatus) => {
      if (action === 'idle') return state;
      return action === 'loading';
    },
    false,
  );

  const { data: userWorkspace, status: userWorkspaceStatus } = useUserWorkspace(
    selectedWorkspace,
  );

  const { data: workspace, status: workspaceStatus } = useWorkspace(
    userWorkspace?.workspaceId,
    userWorkspace?.status === 'DISABLED',
  );

  const [workspaceMisc, setWorkspaceMisc] = useState<{
    id?: string;
    urlPrefix: string;
  }>({
    id: userWorkspace?.workspaceId,
    urlPrefix: userWorkspace?.urlPrefix ?? '',
  });
  const [details, setDetails] = useState<WorkspaceDetails | undefined>();
  useEffect(() => {
    if (keepPreviousWorkspace) return;
    if (!userWorkspace) return;
    if (userWorkspace.status !== 'DISABLED' && !workspace) return;
    if (userWorkspace.status === 'DISABLED') {
      setDetails({
        name: userWorkspace.workspaceName,
        plan: userWorkspace.workspacePlan,
        legacyType: userWorkspace.legacyType,
        status: userWorkspace.status,
      } as WorkspaceDetails);
    } else if (workspace) {
      setDetails({
        name: workspace.name,
        plan: workspace.plan,
        legacyType: userWorkspace.legacyType,
        status: workspace.status,
        memberCount: workspace.memberCount,
        features: workspace.features,
        domains: workspace.domains,
      });
    }
    setWorkspaceMisc({
      id: userWorkspace.workspaceId,
      urlPrefix: userWorkspace.urlPrefix,
    });
  }, [keepPreviousWorkspace, workspace, userWorkspace]);

  const [user, setUser] = useState<WorkspaceUser | undefined>();
  useEffect(() => {
    if (keepPreviousWorkspace) return;
    if (!userWorkspace) return;
    setUser({
      plan: userWorkspace.plan,
      role: userWorkspace.role,
      status: userWorkspace.status,
      counters: userWorkspace.counters,
      features: userWorkspace.features,
    });
  }, [keepPreviousWorkspace, userWorkspace]);

  const [otherWorkspaces, setOtherWorkspaces] = useState<OtherWorkspace[]>(
    DEFAULT_WORKSPACE.otherWorkspaces,
  );
  useEffect(() => {
    if (keepPreviousWorkspace) return;
    if (!userWorkspace) return;
    setOtherWorkspaces(userWorkspace.workspaces);
  }, [keepPreviousWorkspace, userWorkspace]);

  const { alerts, setAlerts, setAlertsSeen } = useAlerts();
  useEffect(() => {
    setAlerts({
      legacy:
        userWorkspace?.role === 'OWNER' &&
        userWorkspace?.workspacePlan === 'LEGACY'
          ? true
          : undefined,
    });
  }, [userWorkspace]);

  const { data: rollout } = useRollout({
    enabled: !!userWorkspace,
  });
  const restrictions = useMemo(
    () =>
      rollout?.restrictions
        ? {
            ...rollout?.restrictions,
          }
        : undefined,
    [rollout?.restrictions],
  );

  const isLoading = useMemo<boolean>(
    () =>
      workspaceStatus !== 'loading' &&
      userWorkspaceStatus !== 'loading' &&
      !details &&
      !user,
    [workspaceStatus, userWorkspaceStatus, details, user],
  );

  const isPreviousWorkspace = useMemo<boolean>(
    () =>
      keepPreviousWorkspace || workspaceMisc.id !== userWorkspace?.workspaceId,
    [keepPreviousWorkspace, workspaceMisc, userWorkspace],
  );

  const { alerts: userAlerts, setAlerts: setUserAlerts } = useUserAlerts();
  const { billingAlerts, setBillingAlerts } = useBillingAlerts();

  const context = useMemo<WorkspaceProps>(
    () => ({
      ...workspaceMisc,
      setSelectedWorkspace,
      isLoading,
      details,
      user,
      otherWorkspaces,
      alerts,
      setAlerts,
      setAlertsSeen,
      setKeepPreviousWorkspace,
      isPreviousWorkspace,
      restrictions,
      userAlerts,
      setUserAlerts,
      billingAlerts,
      setBillingAlerts,
    }),
    [
      workspaceMisc,
      isLoading,
      details,
      user,
      otherWorkspaces,
      alerts,
      setAlerts,
      setAlertsSeen,
      setKeepPreviousWorkspace,
      isPreviousWorkspace,
      restrictions,
      userAlerts,
      setUserAlerts,
      billingAlerts,
      setBillingAlerts,
    ],
  );

  return (
    <WorkspaceContext.Provider value={context}>
      {children}
    </WorkspaceContext.Provider>
  );
};
