import React from 'react';
import { CHECKOUT_TYPE, useConnect } from './connect';
import {SeatsAdded, SeatsRemoved, SubscriptionStarted, SubscriptionStartedForGmailUsers, SubscriptionUpdated} from './alerts';

const CheckoutResultAlerts = () => {
  const { checkoutType, newGmailPaidSeats, newWorkspaceSeats, manageUsers, isGmail, quantityChange } = useConnect();

  return (
    <>
      {checkoutType === CHECKOUT_TYPE.NEW_PLAN_PURCHASE && (
        <>
          {newWorkspaceSeats.quantity > 0 && !isGmail && (
            <SubscriptionStarted
              seatQuantity={newWorkspaceSeats.quantity}
              workspacePlan={newWorkspaceSeats.plan}
              manageUsers={manageUsers}
            />
          )}
          {newGmailPaidSeats.quantity > 0 && isGmail && (
            <SubscriptionStartedForGmailUsers/>
          )}
        </>
      )}
      {checkoutType === CHECKOUT_TYPE.WORKSPACE_SEATS_ADDED && quantityChange > 0 && (
        <>
          <SeatsAdded seatQuantity={quantityChange}/>
          <SubscriptionUpdated/>
        </>
      )}
      {checkoutType === CHECKOUT_TYPE.WORKSPACE_SEATS_REMOVED && quantityChange > 0 && (
        <>
          <SeatsRemoved seatQuantity={quantityChange}/>
          <SubscriptionUpdated/>
        </>
      )}
    </>
  );
};

export default CheckoutResultAlerts;
