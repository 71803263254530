import {
  Alert,
  Button,
  FlexContainer,
  FormGroup,
  FormLabel,
  Input,
  Option,
  Selectbox,
  Text,
} from '@scriptaddicts/yamm-ui-components';
import {RouterLink} from 'components/Link';
import {MainContainer, MainContainerHeader, MainContainerTitle,} from 'components/MainContainer';
import {nanoid} from 'nanoid';
import React from 'react';
import {useConnect} from 'seats/containers/Main/Workspaces/GeneralSettings/connect';
import {TransferValidation} from 'seats/containers/Main/Workspaces/GeneralSettings/TransferValidation';
import {WorkspaceMemberWithSeat} from "seats/model/WorkspaceMemberWithSeats";
import {Card, CardTitle, Container, FormControl} from 'seats/containers/Main/Workspaces/GeneralSettings/styles';

const GeneralSettings = () => {
  const {name, general, owner, urlPrefix} = useConnect();
  return (
    <MainContainer title="General settings">
      <MainContainerHeader direction="vertical">
        <MainContainerTitle append={name}>General settings</MainContainerTitle>
      </MainContainerHeader>
      <Container>
        <Card>
          {general.isSuccess && (
            <Alert type="success" stroke="300">
              <Text type="paragraph" size="large" width="semiBold">
                Your general settings have been saved.
              </Text>
            </Alert>
          )}
          <FormGroup>
            <FormLabel htmlFor="workspace-name-change">Space’s name</FormLabel>
            <FormControl
              errorMessage={(() => {
                if (general.name.error === 'REQUIRED') {
                  return 'Please fill in this field.';
                }
                if (general.name.error === 'EMPTY') {
                  return 'Please fill in this field.';
                }
                if (general.name.error === 'TOO_SHORT') {
                  return 'Space name should contain at least 5 characters.';
                }
                if (general.name.error === 'TOO_LONG') {
                  return 'Space name could contain at most 255 characters.';
                }
                return undefined;
              })()}
            >
              <Input
                id="workspace-name-change"
                placeholder="Enter the space’s name"
                onChange={(e) => general.name.onChange(e.target.value)}
                onSubmit={general.onSave}
                value={general.name.value}
                disabled={general.isLoading}
                hasError={!!general.name.error}
                variant="gray"
              />
            </FormControl>
          </FormGroup>
          <FlexContainer flex="horizontal" gap="base">
            <Button
              aria-label="save general settings"
              size="base"
              onClick={general.onSave}
              disabled={general.disabled || general.isLoading}
              loading={general.isLoading}
            >
              Save
            </Button>
            <Button
              aria-label="reset general settings"
              size="base"
              variant="secondary"
              onClick={general.onCancel}
              disabled={general.disabled || general.isLoading}
            >
              Cancel
            </Button>
          </FlexContainer>
        </Card>
        {owner.isDetailedLoaded && !owner.isRestricted && owner.warning !== 'FREE' && (
          <Card>
            <FlexContainer flex="vertical" gap="base">
              <CardTitle>Transfer ownership</CardTitle>
              {!owner.warning ? (
                <FlexContainer flex="vertical" gap="none">
                  <Text type="paragraph" size="base" width="regular">
                    Only users that are already in your space can become an
                    owner.
                  </Text>
                  <Text type="paragraph" size="base" width="regular">
                    After the transfer, you will remain in the space as a
                    regular user.
                  </Text>
                </FlexContainer>
              ) : (
                 <>
                   {owner.warning === 'FREE' && (
                     <Text type="paragraph" size="base" width="regular">
                       Transferring ownership is not available in free plan.{' '}
                       <RouterLink
                         to={`${urlPrefix}/space/billing`}
                         data-log-event="go_to_space_billing"
                         data-log-event-data={JSON.stringify({
                           displayName: 'Upgrade',
                         })}
                       >
                         Upgrade
                       </RouterLink>{' '}
                       to unlock this feature.
                     </Text>
                   )}
                   {owner.warning === 'LEGACY' && (
                     <Text type="paragraph" size="base" width="regular">
                       Transferring ownership is only available to new YAMM
                       plans.{' '}
                       <RouterLink to={`${urlPrefix}/space/billing`}>
                         Switch to a new plan
                       </RouterLink>{' '}
                       to unlock this feature.
                     </Text>
                   )}
                 </>
               )}
            </FlexContainer>
            {!owner.warning && (
              <>
                <FormGroup>
                  <FormLabel htmlFor="workspace-new-owner">New owner</FormLabel>
                  <FormControl
                    errorMessage={(() => {
                      if (owner.error === 'NOT_SELECTED') {
                        return 'Please fill in this field.';
                      }
                      if (owner.error === 'TOO_MANY_WORKSPACES') {
                        return 'This user already owns too many spaces.';
                      }
                      return undefined;
                    })()}
                  >
                    <Selectbox
                      id="workspace-new-owner"
                      value={owner.value?.id}
                      onChange={(value) =>
                        owner.onChange(
                          owner.options.find(
                            (member: WorkspaceMemberWithSeat) => member.id === value,
                          ),
                        )
                      }
                      onSubmit={owner.onTransfer}
                      disabled={!owner.options.length}
                      hasError={!!owner.error}
                      placeholder="Select a new owner"
                      variant="gray"
                      maxElement="auto"
                      minElement={5}
                    >
                      {owner.options.map((member: WorkspaceMemberWithSeat) => (
                        <Option key={nanoid()} id={nanoid()} value={member.id}>
                          {member.name}
                        </Option>
                      ))}
                      ;
                    </Selectbox>
                  </FormControl>
                </FormGroup>
                <FlexContainer flex="horizontal" gap="base">
                  <Button
                    aria-label="transfer to new owner"
                    size="base"
                    onClick={owner.onTransfer}
                    disabled={general.isLoading || owner.disabled}
                  >
                    Save
                  </Button>
                  <Button
                    aria-label="reset transfer ownership settings"
                    size="base"
                    variant="secondary"
                    onClick={owner.onCancel}
                    disabled={general.isLoading || owner.disabled}
                  >
                    Cancel
                  </Button>
                </FlexContainer>
              </>
            )}
          </Card>
        )}
        <TransferValidation {...owner} />
      </Container>
    </MainContainer>
  );
};

export default GeneralSettings;
