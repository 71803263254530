import { Alert, Text } from '@scriptaddicts/yamm-ui-components';
import React from 'react';
import { Links } from '../../../../../../data/links';
import { BlueLink } from './styles';
import { MAX_USERS_PER_SPACE } from '../../../../../../data/constants';

export const MaxUsersReachedAlert = () => (
  <Alert type="warning" stroke="300">
    <Text type="paragraph" size="small" width="semiBold">
      Your current plan allows a maximum of {MAX_USERS_PER_SPACE} paid users.
    </Text>
    <Text type="paragraph" size="small" width="regular">
      <BlueLink href={Links.CONTACT_US} target="_blank">
        Need more users? Contact us
      </BlueLink>
    </Text>
  </Alert>
);

export const OwnerCannotBeRemoved = () => (
  <Alert type="warning" stroke="300">
    <Text type="paragraph" size="small" width="regular">
      The owner cannot be removed from the space
    </Text>
  </Alert>
);

export const FreeTrialUsersCannotBeRemoved = () => (
  <Alert type="warning" stroke="300">
    <Text type="paragraph" size="small" width="regular">
      You cannot remove the user in Free trial
    </Text>
  </Alert>
);

export const AdminCannotBeRemovedByHimself = () => (
  <Alert type="warning" stroke="300">
    <Text type="paragraph" size="small" width="regular">
      You cannot remove yourself from the space
    </Text>
  </Alert>
);

export const AdminCannotBeRemovedByAdmin = () => (
    <Alert type="warning" stroke="300">
        <Text type="paragraph" size="small" width="regular">
            An admin cannot be removed by another admin from the space
        </Text>
    </Alert>
);
