import { useDisclosure } from '@scriptaddicts/yamm-ui-components';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMe } from 'data/hooks/useMe';
import {
  useChangeMemberRole,
} from 'data/hooks/useWorkspaceMembers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useWorkspaceActiveSubscriptionV2 } from "seats/data/hooks/useSeatsWorkspaceBilling";
import { UserRole, ActiveUsers, Props } from './types';
import { useActiveUsers } from './hooks/useActiveUsers';
import { useMaxUsersCheck } from './hooks/useMaxUsersCheck';
import {usePendingUsers} from "./hooks/usePendingUsers";


const hasMemberRoleChanged = (
  active: ActiveUsers,
  selectedRole: UserRole,
): boolean =>
  active.features.change.role &&
  !active.disabled.change.role &&
  active.list.filter((member) => member.role !== selectedRole).length > 0;

export const useConnect = ({
  selected,
  setUserAlerts,
  setBusy,
  onClose,
  limit,
  allUsers,
}: Props) => {
  const { data: me } = useMe({ keepPreviousData: true });
  const { id, details, user, urlPrefix, restrictions } = useWorkspaceContext();
  const { data: activeSubscription } = useWorkspaceActiveSubscriptionV2({
    workspaceId: id});

  const active = useActiveUsers({
    workspaceId: id,
    selected,
    allUsers,
    me,
    workspace: details,
    workspaceUser: user,
    isMultiplePaymentRestricted: !!restrictions?.MULTIPLE_PAYMENT,
    activeSubscription,
  });

  const pending = usePendingUsers({
    selected,
  });

  const [selectedRole, setSelectedRole] = useState<'MEMBER' | 'ADMIN'>(
    'MEMBER',
  );
  const {
    status: roleStatus,
    mutateAsync: changeRole,
    reset: roleReset,
  } = useChangeMemberRole();

  useEffect(() => {
    if (roleStatus === 'success') {
      roleReset();
      setUserAlerts({
        roleChangedMembers: active.list.map(({ name }) => name),
      });
      onClose();
    }
  }, [roleReset, setUserAlerts, onClose, roleStatus, active.list]);

  const activeFormChanged = useMemo<Record<string, boolean>>(
    () => ({
      role: active.list.some(
        ({ role }) => role !== 'OWNER' && role !== selectedRole,
      ),
    }),
    [active.list, selectedRole],
  );

  const changeMemberButtonRef = useRef<HTMLButtonElement>(null);
  const changeMember = useCallback(async () => {
    if (hasMemberRoleChanged(active, selectedRole)) {
      await changeRole({
        workspaceId: id,
        params: {
          memberRoles: active.list.map(({ id: userId }) => ({
            userId,
            userRole: selectedRole,
          })),
        },
      });
    }
  }, [
    changeRole,
    id,
    active,
    active.list,
    selectedRole,
    urlPrefix,
  ]);

  const cancelMember = useCallback(() => {
    setSelectedRole(
      !active.role || active.role === 'OWNER' ? 'MEMBER' : active.role,
    );
  }, [setSelectedRole, active.type, active.role]);
  useEffect(() => {
    cancelMember();
  }, [active.role, active.type, cancelMember]);

  const removeActiveMembers = useDisclosure({});
  const removePendingMembers = useDisclosure({});

  const isLoading = useMemo(() => {
    return (
      roleStatus === 'loading'
    );
  }, [roleStatus]);
  useEffect(() => {
    setBusy(isLoading);
  }, [isLoading]);

  const { isMaxUsersReached } = useMaxUsersCheck({
    selected,
    allUsers,
    activeSubscription,
  });

  return {
    active,
    pending,
    isLoading,
    activeFormChanged,
    isMaxUsersReached,
    disablePlanUpdate: true,
    removeActiveMembers: {
      ...removeActiveMembers,
      members: active.list,
      setUserAlerts,
      onCloseDrawer: onClose,
    },
    removePendingMembers: {
      ...removePendingMembers,
      members: pending.list,
      setUserAlerts,
      onCloseDrawer: onClose,
    },
    changeMembers: {
      role: {
        value: selectedRole,
        onChange: (v: any) => setSelectedRole(v as 'MEMBER' | 'ADMIN'),
      },
      onSave: changeMember,
      onCancel: () => {
        cancelMember();
        onClose();
      },
      isLoading: roleStatus === 'loading',
    },
    changeMemberButtonRef,
    memberLimit: limit.freeUsersMax,
    urlPrefix,
  };
};
