import { useMemo } from 'react';
import {WorkspaceMemberWithSeat} from "seats/model/WorkspaceMemberWithSeats";
import { MAX_USERS_PER_SPACE } from 'data/constants';
import {WorkspaceSubscriptionV2} from "seats/model/workspaceBilling";

export type UseMaxUsersCheckProps = {
  selected: WorkspaceMemberWithSeat[];
  allUsers: WorkspaceMemberWithSeat[];
  activeSubscription?: WorkspaceSubscriptionV2;
};
export const useMaxUsersCheck = ({
  allUsers,
  activeSubscription,
  selected,
}: UseMaxUsersCheckProps) => {
  return useMemo(() => {
    const selectedFreeUsers = selected.filter((user) => user.plan === 'FREE');
    const allPaidUsers = allUsers.filter((user) => user.plan !== 'FREE');
    const isMaxUsersReached =
      allPaidUsers.length + selectedFreeUsers.length > MAX_USERS_PER_SPACE;

    return {
      isMaxUsersReached,
    };
  }, [selected, allUsers, activeSubscription]);
};
