import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import { useMemo } from 'react';
import { useMe } from 'data/hooks/useMe';
import {isOfflineSubscription, isSupportGivenSubscription} from "../../../../utils/subscriptions";
import {useWorkspaceActiveSubscriptionV2} from "../../../data/hooks/useSeatsWorkspaceBilling";

export const useConnect = () => {
  const { otherWorkspaces } = useWorkspaceContext();
  const { data: user } = useMe();

  const activeWorkspace = useMemo(
    () => otherWorkspaces.find((a) => a.id === user?.addonWorkspaceId),
    [otherWorkspaces, user?.addonWorkspaceId],
  );

  const {data: activeSubscription} = useWorkspaceActiveSubscriptionV2({
    workspaceId: activeWorkspace?.id,
    userRole: activeWorkspace?.role
  });

  const hasOfflineSubscription = activeSubscription && isOfflineSubscription(activeSubscription);
  const hasSupportGivenSubscription = activeSubscription && isSupportGivenSubscription(activeSubscription);

  return {
    hasOfflineSubscription,
    hasSupportGivenSubscription,
    activeWorkspace
  };
};
