import React from 'react';
import {Alert, Text} from '@scriptaddicts/yamm-ui-components';
import {plurify} from 'utils/plurify';
import {formatWorkspacePlan} from 'seats/containers/Checkout/common/utils';
import {AlertManageUsersButton} from "seats/containers/Main/Workspaces/Billing/CheckoutResultAlerts/styles";

type SubscriptionStartedProps = {
  seatQuantity: number,
  workspacePlan: 'PRO' | 'PREMIUM',
  manageUsers: () => void,
};

export const SubscriptionStarted = ({
  seatQuantity,
  workspacePlan,
  manageUsers,
}: SubscriptionStartedProps) => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
      Well done!
    </Text>
    <Text type="paragraph" size="base" width="regular">
      You successfully purchased {seatQuantity} {formatWorkspacePlan(workspacePlan)} {plurify(
      seatQuantity,
      'seat',
      'seats'
    )} for your space.
    </Text>
    <AlertManageUsersButton aria-label="manage-users" onClick={manageUsers}>Manage users</AlertManageUsersButton>
  </Alert>
);

export const SubscriptionStartedForGmailUsers = () => (
    <Alert type="success">
        <Text type="paragraph" size="large" width="semiBold">
            Well done! You successfully upgraded your space to Pro.
        </Text>
    </Alert>
);

export const SubscriptionUpdated = () => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
      Your subscription has been updated.
    </Text>
  </Alert>
);

export const SeatsAdded = ({seatQuantity}: { seatQuantity: number }) => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
      Well done! You successfully added {seatQuantity} {plurify(seatQuantity, 'seat', 'seats')} to your space.
    </Text>
  </Alert>
);

export const SeatsRemoved = ({seatQuantity}: { seatQuantity: number }) => (
  <Alert type="success">
    <Text type="paragraph" size="large" width="semiBold">
        You successfully scheduled the removal of {seatQuantity} {plurify(seatQuantity, 'seat', 'seats')} from your space.
    </Text>
  </Alert>
);