import React from 'react';
import {FlexContainer, TableCell, Text} from "@scriptaddicts/yamm-ui-components";
import {FooterCancelDowngradeButton, FooterRow} from "seats/containers/Main/Workspaces/UserSettings/ScheduledDowngradeAsFooter/styles";
import {plurify} from "utils/plurify";

interface ScheduledDowngradeAsFooterProps {
  scheduledSeatsForRemoval: number;
  onCancelScheduledDowngrade: () => void;
}

const ScheduledDowngradeAsFooter: React.FC<ScheduledDowngradeAsFooterProps> = ({scheduledSeatsForRemoval, onCancelScheduledDowngrade}) => {
  return (
    <tfoot>
    <FooterRow>
      <TableCell colSpan={4} key="footer-scheduled-removal">
        <FlexContainer element="stretch" flex="horizontal" style={{justifyContent: "space-between", width: "100%"}}>
          <Text type="paragraph" size="base" style={{fontStyle: "italic"}}>{scheduledSeatsForRemoval} {plurify(
            scheduledSeatsForRemoval,
            'seat'
          )} scheduled for removal</Text>
          <FooterCancelDowngradeButton onClick={onCancelScheduledDowngrade}>Cancel downgrade</FooterCancelDowngradeButton>
        </FlexContainer>
      </TableCell>
    </FooterRow>
    </tfoot>
  );
};

export default ScheduledDowngradeAsFooter;