import styled from "styled-components";
import {Button, Text} from "@scriptaddicts/yamm-ui-components";

export const RemoveSeatsPageContainer = styled.div`
    display: flex;
    width: 700px;
    flex-direction: column;
    align-items: center;
    gap: 16px;
`;

export const RemoveSeatsPageBody = styled.div`
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.colors.gray300};
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const RemoveSeatsPageBodyContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
`;

export const RemoveSeatsPageBodyTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
    align-self: stretch;
`;

export const ManageSeatsPageFooterButtons = styled.div`
    display: flex;
    padding-top: 10px;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
`;

export const RemoveSeatsPageTitleContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
`;

export const ManageSeatsPageTitle = styled(Text)`
    color: ${(props) => props.theme.colors.gray900};
    font-family: ${(props) => props.theme.fonts.lexendDeca};
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const RemoveSeatsPageSubtitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
`;

export const ManageSeatsPageButton = styled(Button).attrs({})`
    display: flex;
    height: 40px;
    padding: 0px 16px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 5px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
`;

export const DropdownOuterContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
`;

export const DropdownInnerContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
`;
export const DropdownWrapper = styled.div`
    display: flex;
    width: 89px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`;

export const BoldMessageText = styled(Text)`
    align-self: stretch;
    color: ${(props) => props.theme.colors.gray800};
    font-family: ${(props) => props.theme.fonts.inter};
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
`;

export const MessageText = styled(Text).attrs({type: "span"})`
    color: ${(props) => props.theme.colors.gray700};
    font-variant-numeric: lining-nums tabular-nums;
    font-family: ${(props) => props.theme.fonts.inter};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.092px;
    align-self: stretch;
`;

export const CurrentSeatsQuantity = styled(Text)`
    color: ${(props) => props.theme.colors.gray800};
    font-family: ${(props) => props.theme.fonts.inter};
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
`;