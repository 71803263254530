import { useMemo } from 'react';
import { useWorkspaceContext } from 'data/context/WorkspaceContext';
import {
  useGetManageStripeCustomerPortalData,
} from 'data/hooks/useWorkspaceBilling';
import {
  useFeatureRestrictions,
  usePageRestrictions,
} from 'data/hooks/restrictions/useRestrictions';
import { useWorkspaceActiveSubscriptionV2 } from 'seats/data/hooks/useSeatsWorkspaceBilling';
import { WorkspaceSubscriptionV2 } from 'seats/model/workspaceBilling';
import { useMe } from 'data/hooks/useMe';
import useSubscriptionSeat from "seats/data/hooks/useSubscriptionSeat";


export const useConnect = () => {
  const { id, urlPrefix, details, user } = useWorkspaceContext();
  const { isLoading: isLoadingUser, isGmailUser } = useMe();
  const {
    data: subscription,
    isLoading: isLoadingSubscription,
  } = useWorkspaceActiveSubscriptionV2({
    workspaceId: id,
    userRole: user?.role,
  });

  const {subscriptionSeat, isLoadingSubscriptionSeat} = useSubscriptionSeat(id, user?.role);
  const pageRestrictions = usePageRestrictions();
  const featureRestrictions = useFeatureRestrictions();

  const {
    data: manageSubscriptionData,
    isLoading: isLoadingSubscriptionLink,
  } = useGetManageStripeCustomerPortalData({
    workspaceId:
      subscription != null && subscription?.paymentSource === 'STRIPE'
        ? id
        : undefined,
    returnUrl: window.location.href,
  });

  return {
    workspaceId: id,
    isLoading:
    isLoadingUser || isLoadingSubscription || isLoadingSubscriptionLink || isLoadingSubscriptionSeat,
    manageSubscriptionData,
    subscription,
    urlPrefix,
    isLegacy: subscription?.workspacePlan === 'LEGACY',
    isPayPal: subscription?.paymentSource === 'PAYPAL',
    pageRestrictions,
    featureRestrictions,
    legacyPlanType: details?.legacyType,
    isGmailUser,
    subscriptionSeat,
  };
};

export const useFuturePrice = (subscription?: WorkspaceSubscriptionV2) => {
  const futurePrice = useMemo(() => {
    return subscription?.additionalStripeInfo?.upcomingInvoice?.amountDue;
  }, [subscription]);

  return {
    futurePrice,
  };
};
