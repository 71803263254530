import {
  Button,
  Dialog,
  FlexContainer,
  Text,
} from '@scriptaddicts/yamm-ui-components';
import React, { FC } from 'react';
import { useConnect } from './connect';
import { Title, Container, ActionContainer } from './styles';
import { Props } from './types';

export const RemoveValidation: FC<Props> = ({
  show,
  onClose,
  members,
  setUserAlerts,
  onCloseDrawer,
}) => {
  const {
    workspaceName,
    isLoading,
    onRemove,
    memberNames,
  } = useConnect({
    show,
    onClose,
    members,
    setUserAlerts,
    onCloseDrawer,
  });

  return (
    <Dialog show={show} onClose={() => !isLoading && onClose()}>
      <Container>
        <FlexContainer flex="vertical" gap="medium">
          <Title>
            {memberNames.length > 1
              ? `Remove these users from ${workspaceName}?`
              : `Remove ${memberNames[0]} from ${workspaceName}?`}
          </Title>
          <Text
            type="paragraph"
            size="base"
            width="regular"
            style={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}
          >
            {
              'They will no longer have access to this Space.\n'
            }
          </Text>
        </FlexContainer>
        <ActionContainer>
          <Button
            aria-label="remove from space"
            size="base"
            variant="primary"
            onClick={onRemove}
            disabled={isLoading}
            loading={isLoading}
          >
            Yes, proceed
          </Button>
          <Button
            aria-label="cancel"
            size="base"
            variant="secondary"
            disabled={isLoading}
            onClick={onClose}
          >
            No, cancel
          </Button>
        </ActionContainer>
      </Container>
    </Dialog>
  );
};
