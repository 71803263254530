import {useReducer} from 'react';
import {BillingAlertsProps} from 'seats/containers/Main/Workspaces/Billing/BillingAlerts/types';

export const useBillingAlerts = () => {
  const [billingAlerts, setBillingAlerts] = useReducer(
    (_state: BillingAlertsProps, action: Partial<BillingAlertsProps>) =>
      ({
        ...action,
      } as BillingAlertsProps),
    {} as BillingAlertsProps,
  );

  return {
    billingAlerts,
    setBillingAlerts,
  };
};
