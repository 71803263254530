import React, {useEffect, useState} from "react";
import {ReactComponent as SpinnerMinusIcon} from 'assets/images/icons/input-spinner-minus.svg';
import {ReactComponent as SpinnerMinusInvalidIcon} from 'assets/images/icons/input-spinner-minus-invalid.svg';
import {ReactComponent as SpinnerPlusIcon} from 'assets/images/icons/input-spinner-plus.svg';
import {ReactComponent as SpinnerPlusInvalidIcon} from 'assets/images/icons/input-spinner-plus-invalid.svg';
import {ReactComponent as WarningIcon} from 'assets/images/icons/alert-triangle-red.svg';

import {
  DecreaseButton,
  IncreaseButton, NumberInputSpinnerBody,
  NumberInputSpinnerComponent,
  NumberInputSpinnerContainer,
  NumberInputSpinnerValidation,
  SpinnerInput, ValidationMessage
} from "seats/components/NumberInputSpinner/styles";

interface NumberInputSpinnerProps {
  minValue: number;
  maxValue: number;
  initialValue: number;
  onChange: (value: number) => void;
  onInvalid: () => void;
}

const isInteger = (str: string) => {
  return /^\d+$/.test(str);
};

const isInRange = (str: string, minValue: number, maxValue: number) => {
  if (isInteger(str)) {
    const num = Number(str);
    return num >= minValue && num <= maxValue;
  }
  return false;
};

export const NumberInputSpinner = ({
    minValue,
    maxValue,
    initialValue,
    onChange,
    onInvalid,
    children,
  }: React.PropsWithChildren<NumberInputSpinnerProps>) => {

    const [value, setValue] = useState<string | number>(initialValue);
    const [decreaseButtonEnabled, setDecreaseButtonEnabled] = useState(true)
    const [increaseButtonEnabled, setIncreaseButtonEnabled] = useState(true)
    const [isValid, setIsValid] = useState(true);

    useEffect(() => {
      setValue(initialValue)
      setDecreaseButtonEnabled(initialValue > minValue)
      setIncreaseButtonEnabled(initialValue < maxValue)
    }, [initialValue])

  useEffect(() => {
    if (isInteger(value.toString()) && isInRange(value.toString(), minValue, maxValue)) {
      const num = Number(value);
      setIsValid(true);
      setDecreaseButtonEnabled(num > minValue);
      setIncreaseButtonEnabled(num < maxValue);
      onChange(num);
    } else {
      setIsValid(false)
      setDecreaseButtonEnabled(false);
      setIncreaseButtonEnabled(false);
      onInvalid();
    }
  }, [value]);

    const handleIncrement = () => {
      if(increaseButtonEnabled){
        const changedValue = Number(value) + 1;
        setValue(changedValue);
      }
    };

    const handleDecrement = () => {
      if(decreaseButtonEnabled){
        const changedValue = Number(value) - 1;
        setValue(changedValue);
      }
    };

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const typedValue = e.target.value;
      setValue(typedValue);
    };

    return (
      <NumberInputSpinnerContainer>
        <NumberInputSpinnerBody>
          <NumberInputSpinnerComponent isValid={isValid}>
            <DecreaseButton
              className={!decreaseButtonEnabled ? 'disabled' : ''}
              onClick={handleDecrement}
            >
              {decreaseButtonEnabled ? (<SpinnerMinusIcon width="24px" height="24px"/>) : (
                <SpinnerMinusInvalidIcon width="24px" height="24px"/>)}
            </DecreaseButton>
            <SpinnerInput
              type="number"
              value={value}
              onChange={handleOnChange}
            />
            <IncreaseButton className={!increaseButtonEnabled ? 'disabled' : ''} onClick={handleIncrement}>
              {increaseButtonEnabled ? (<SpinnerPlusIcon width="24px" height="24px"/>) : (
                <SpinnerPlusInvalidIcon width="24px" height="24px"/>)}
            </IncreaseButton>
          </NumberInputSpinnerComponent>
          {children}
        </NumberInputSpinnerBody>
        {
          !isValid && (
                     <NumberInputSpinnerValidation>
                       <WarningIcon width="16px" height="16px"/>
                       <ValidationMessage>The number must be between {minValue} and {maxValue}.</ValidationMessage>
                     </NumberInputSpinnerValidation>
                   )
        }
      </NumberInputSpinnerContainer>
    );
  }
;