import {useWorkspaceContext} from 'data/context/WorkspaceContext';
import {from, useMediaQuery} from 'styles/media';
import {useHistory} from "react-router-dom";
import useSubscriptionSeat from "seats/data/hooks/useSubscriptionSeat";
import {useCallback, useMemo} from "react";
import {useDisclosure} from "@scriptaddicts/yamm-ui-components";
import {MAX_USERS_PER_SPACE} from "data/constants";
import {useWorkspaceActiveSubscriptionV2} from "seats/data/hooks/useSeatsWorkspaceBilling";
import {isOfflineSubscription, isSupportGivenSubscription} from "utils/subscriptions";

export const useConnect = () => {
  const manageSeatsDisclosure = useDisclosure({});

  const {id: workspaceId, urlPrefix, isLoading: isLoadingWorkspace, user} = useWorkspaceContext();
  const userRole= user?.role;
  const {data: activeSubscription, isLoading: isLoadingActiveSubscription} = useWorkspaceActiveSubscriptionV2({
    workspaceId,
    userRole,
  });
  const {subscriptionSeat, isLoadingSubscriptionSeat} = useSubscriptionSeat(workspaceId, user?.role);

  const {push} = useHistory();

  const isSeatsLimitReached = useMemo(() => {
    return MAX_USERS_PER_SPACE === subscriptionSeat.totalSeats;
  }, [subscriptionSeat.totalSeats]);

  const isOfflineOrSupportGivenSubscription = useMemo(() => {
    return activeSubscription && (isOfflineSubscription(activeSubscription) || isSupportGivenSubscription(activeSubscription));
  }, [activeSubscription]);

  const onAddSeats = useCallback(() => {
    if (!isSeatsLimitReached) {
      push(`${urlPrefix}/checkout/add-seats`);
    }
  }, [isSeatsLimitReached, push, urlPrefix]);

  const onRemoveUnusedSeats = useCallback(() => {
    const target = `${urlPrefix}/checkout/remove-seats`
    push(target);
  }, [urlPrefix, push]);

  const onCancelScheduledDowngrade = useCallback(() => {
    push(`${urlPrefix}/space/billing`, {openCancelScheduledRemovalDialog: true});
  }, [urlPrefix, push]);

  const canManageSeats = useMemo(() => {
    return user!.role === 'OWNER';
  }, [user]);

  const isMobile = !useMediaQuery(from.tablet);

  return {
    isLoading: isLoadingWorkspace || isLoadingSubscriptionSeat || isLoadingActiveSubscription,
    isMobile,
    subscriptionSeat,
    onCancelScheduledDowngrade,
    onAddSeats,
    onRemoveUnusedSeats,
    manageSeatsDisclosure,
    canManageSeats,
    isSeatsLimitReached,
    isOfflineOrSupportGivenSubscription,
  };
}
